import { PayloadAction } from '@reduxjs/toolkit';
import { fork, put, select, take } from 'redux-saga/effects';
import { Models, QITech, RPC } from 'types';
import { WebBankAccountHelpers } from '../../data-helpers/WebBankAccountHelpers';
import { WebTargetBankAccountHelpers } from '../../data-helpers/WebTargetBankAccountHelpers';
import { appActions } from '../app/app-slice';
import { AppState } from '../app/app.types';
import { notifierActions } from '../notifier/notifier-slice';
import { bankAccountActions } from './bank-account-slice';

function* createAccount(payload: Models.WebCreateBankAccountRequest) {
  yield put(appActions.startLoading());
  const rpcCallResult: RPC.Call<void, Models.WebCreateBankAccountResponse> =
    yield WebBankAccountHelpers.createAccount(payload);
  const { result, resultPayload, error } = rpcCallResult;

  if (result === 'success') {
    if (!resultPayload) throw new Error('Unexpected empty payload');
    const { createdAccount } = resultPayload;
    yield put(appActions.setBankAccount(createdAccount));
    yield put(
      notifierActions.enqueue({
        message: `Conta criada com sucesso. Agência ${createdAccount.branch} - Número ${createdAccount.account}-${createdAccount.accountDigit}.`,
        options: {
          variant: 'success',
        },
      })
    );
    yield put(appActions.finishLoading());
  } else {
    yield put(appActions.setError(error ?? 'Error'));
    yield put(appActions.finishLoading());
  }
}

function* watchCreateAccount() {
  while (true) {
    const { payload }: PayloadAction<Models.WebCreateBankAccountRequest> =
      yield take(bankAccountActions.createAccount);
    yield fork(createAccount, payload);
  }
}

function* getSupplierAccountInfo() {
  yield put(bankAccountActions.setIsLoadingBankAccountInfo(true));
  const rpcCallResult: RPC.Call<
    void,
    QITech.GetAccountInfoResponseWithBankStatus
  > = yield WebBankAccountHelpers.getSupplierAccountInfo();
  const { result, resultPayload, error } = rpcCallResult;

  if (result === 'success') {
    if (!resultPayload) throw new Error('Unexpected empty payload');
    const { bankAccount }: AppState = yield select((state) => state.app);

    yield put(bankAccountActions.setAccountInfo(resultPayload));
    if (bankAccount && bankAccount.bankStatus !== resultPayload.bankStatus)
      yield put(
        appActions.setBankAccount({
          ...bankAccount,
          bankStatus: resultPayload.bankStatus,
        })
      );
    yield put(bankAccountActions.setIsLoadingBankAccountInfo(false));
  } else {
    yield put(appActions.setError(error ?? 'Error'));
    yield put(bankAccountActions.setIsLoadingBankAccountInfo(false));
  }
}

function* getRetailerAccountInfo(idRetailer: string) {
  yield put(bankAccountActions.setIsLoadingBankAccountInfo(true));
  const rpcCallResult: RPC.Call<
    void,
    QITech.GetAccountInfoResponseWithBankStatus
  > = yield WebBankAccountHelpers.getRetailerAccountInfo(idRetailer);
  const { result, resultPayload, error } = rpcCallResult;

  if (result === 'success') {
    if (!resultPayload) throw new Error('Unexpected empty payload');
    const { bankAccount }: AppState = yield select((state) => state.app);

    yield put(bankAccountActions.setAccountInfo(resultPayload));
    if (bankAccount && bankAccount.bankStatus !== resultPayload.bankStatus)
      yield put(
        appActions.setBankAccount({
          ...bankAccount,
          bankStatus: resultPayload.bankStatus,
        })
      );
    yield put(bankAccountActions.setIsLoadingBankAccountInfo(false));
  } else {
    yield put(appActions.setError(error ?? 'Error'));
    yield put(bankAccountActions.setIsLoadingBankAccountInfo(false));
  }
}

function* getPixLimitsUsage() {
  yield put(bankAccountActions.setIsLoadingPixLimitsUsage(true));
  const rpcCallResult: RPC.Call<void, QITech.GetPixLimitUsageResponse> =
    yield WebBankAccountHelpers.getPixLimitsUsage();
  const { result, resultPayload, error } = rpcCallResult;

  if (result === 'success') {
    if (!resultPayload) throw new Error('Unexpected empty payload');
    yield put(bankAccountActions.setPixLimitsUsage(resultPayload));
    yield put(bankAccountActions.setIsLoadingPixLimitsUsage(false));
  } else {
    yield put(appActions.setError(error ?? 'Error'));
    yield put(bankAccountActions.setIsLoadingPixLimitsUsage(false));
  }
}

function* watchGetSupplierAccountInfo() {
  while (true) {
    yield take(bankAccountActions.getSupplierAccountInfo);
    yield fork(getSupplierAccountInfo);
    yield fork(getPixLimitsUsage);
  }
}

function* watchGetRetailerAccountInfo() {
  while (true) {
    const { payload: idRetailer }: PayloadAction<string> = yield take(
      bankAccountActions.getRetailerAccountInfo
    );
    yield fork(getRetailerAccountInfo, idRetailer);
  }
}

function* searchPixKey(pixKey: string) {
  yield put(bankAccountActions.setIsSearchingPixKey(true));
  const rpcCallResult: RPC.Call<void, QITech.PixKey> =
    yield WebBankAccountHelpers.searchPixKey(pixKey);
  const { result, resultPayload } = rpcCallResult;
  yield put(
    bankAccountActions.setSearchPixKeyResponse({ result, resultPayload })
  );
  yield put(bankAccountActions.setIsSearchingPixKey(false));
}

function* watchSearchPixKey() {
  while (true) {
    const { payload }: PayloadAction<string> = yield take(
      bankAccountActions.searchPixKey
    );
    yield fork(searchPixKey, payload);
  }
}

function* watchCreateTargetBankAccount() {
  while (true) {
    try {
      const { payload }: PayloadAction<Models.SaveTargetBankAccountPayload> =
        yield take(bankAccountActions.createTargetBankAccount);
      yield put(bankAccountActions.setIsSavingTargetAccount(true));
      const created: Models.TargetBankAccount =
        yield WebTargetBankAccountHelpers.create(payload);
      if (!created) throw new Error('Error! Account not created');
      yield put(appActions.setTargetBankAccount(created));
      yield put(bankAccountActions.setIsSavingTargetAccount(false));
      yield put(bankAccountActions.setIsTargetAccountInEditMode(false));
      yield put(
        notifierActions.enqueue({
          message: 'Conta atualizada com sucesso!',
          options: {
            variant: 'success',
          },
        })
      );
    } catch (e) {
      yield put(appActions.setError((e as Error).message));
      yield put(bankAccountActions.setIsSavingTargetAccount(false));
    }
  }
}
function* watchUpdateTargetBankAccount() {
  while (true) {
    try {
      const { payload }: PayloadAction<Models.UpdateTargetBankAccountPayload> =
        yield take(bankAccountActions.updateTargetBankAccount);
      yield put(bankAccountActions.setIsSavingTargetAccount(true));
      const updated: Models.TargetBankAccount =
        yield WebTargetBankAccountHelpers.patch(payload);
      if (!updated) throw new Error('Error! Account not updated');
      yield put(appActions.setTargetBankAccount(updated));
      yield put(bankAccountActions.setIsSavingTargetAccount(false));
      yield put(bankAccountActions.setIsTargetAccountInEditMode(false));
      yield put(
        notifierActions.enqueue({
          message: 'Conta atualizada com sucesso!',
          options: {
            variant: 'success',
          },
        })
      );
    } catch (e) {
      yield put(appActions.setError((e as Error).message));
      yield put(bankAccountActions.setIsSavingTargetAccount(false));
    }
  }
}

function* transferToTargetAccount(
  payload: Models.TransferToTargetAccountRequest
) {
  yield put(bankAccountActions.setIsTransferring(true));
  const rpcCallResult: RPC.Call<void, void> =
    yield WebBankAccountHelpers.transferToTargetAccount(payload);
  const { result, error } = rpcCallResult;
  if (result === 'success') {
    yield put(
      notifierActions.enqueue({
        message: 'Requisição enviada com sucesso, aguarde atualização do saldo',
        options: {
          variant: 'success',
        },
      })
    );
    yield put(bankAccountActions.setIsTransferring(false));
    yield put(bankAccountActions.setIsTransferConfirmationOpen(false));
    yield put(bankAccountActions.setIsTransferDialogOpen(false));
    yield fork(getSupplierAccountInfo);
  } else {
    yield put(appActions.setError(error ?? 'Error'));
    yield put(bankAccountActions.setIsTransferring(false));
    yield put(bankAccountActions.setIsTransferDialogOpen(false));
  }
}

function* watchTransferToTargetAccount() {
  while (true) {
    const { payload }: PayloadAction<Models.TransferToTargetAccountRequest> =
      yield take(bankAccountActions.transferToTargetAccount);
    yield fork(transferToTargetAccount, payload);
  }
}

function* internalTransferToTargetAccount(
  payload: Models.InternalTransferToTargetAccountRequest
) {
  yield put(bankAccountActions.setIsTransferring(true));
  const rpcCallResult: RPC.Call<void, void> =
    yield WebBankAccountHelpers.internalTransferToTargetAccount(payload);
  const { result, error } = rpcCallResult;
  if (result === 'success') {
    yield put(
      notifierActions.enqueue({
        message: 'Requisição enviada com sucesso, aguarde atualização do saldo',
        options: {
          variant: 'success',
        },
      })
    );
    yield put(bankAccountActions.setIsTransferring(false));
    yield put(bankAccountActions.setIsInternalTransferConfirmationOpen(false));
    yield put(bankAccountActions.setIsInternalTransferDialogOpen(false));
    yield fork(getRetailerAccountInfo, payload.idRetailer);
  } else {
    yield put(appActions.setError(error ?? 'Error'));
    yield put(bankAccountActions.setIsTransferring(false));
    yield put(bankAccountActions.setIsInternalTransferDialogOpen(false));
  }
}

function* watchInternalTransferToTargetAccount() {
  while (true) {
    const {
      payload,
    }: PayloadAction<Models.InternalTransferToTargetAccountRequest> =
      yield take(bankAccountActions.internalTransferToTargetAccount);
    yield fork(internalTransferToTargetAccount, payload);
  }
}

function* requestNewPixLimits(payload: Models.NewPixLimitsRequest) {
  yield put(bankAccountActions.setIsRequestingNewPixLimits(true));
  const rpcCallResult: RPC.Call<
    Models.NewPixLimitsRequest,
    QITech.CreatePixLimitRequestResponse
  > = yield WebBankAccountHelpers.requestNewPixLimits(payload);
  const { result, error } = rpcCallResult;
  if (result === 'success') {
    yield put(
      notifierActions.enqueue({
        message:
          'Requisição enviada com sucesso, aguarde atualização do limite',
        options: {
          variant: 'success',
        },
      })
    );
    yield put(bankAccountActions.setIsRequestingNewPixLimits(false));
    yield put(bankAccountActions.setIsPixLimitsFormOpen(false));
    yield fork(getSupplierAccountInfo);
  } else {
    yield put(appActions.setError(error ?? 'Error'));
    yield put(bankAccountActions.setIsRequestingNewPixLimits(false));
    yield put(bankAccountActions.setIsPixLimitsFormOpen(false));
  }
}

function* watchRequestNewPixLimits() {
  while (true) {
    const { payload }: PayloadAction<Models.NewPixLimitsRequest> = yield take(
      bankAccountActions.requestNewPixLimits
    );
    yield fork(requestNewPixLimits, payload);
  }
}

export default [
  watchCreateAccount,
  watchGetSupplierAccountInfo,
  watchGetRetailerAccountInfo,
  watchSearchPixKey,
  watchCreateTargetBankAccount,
  watchUpdateTargetBankAccount,
  watchTransferToTargetAccount,
  watchInternalTransferToTargetAccount,
  watchRequestNewPixLimits,
];
