import { PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { NavigateFunction } from 'react-router-dom';
import { EventChannel, Task } from 'redux-saga';
import {
  CancelledEffect,
  call,
  cancel,
  cancelled,
  fork,
  put,
  select,
  take,
} from 'redux-saga/effects';
import { Models, RPC, Supplier } from 'types';
import { v4 as uuid } from 'uuid';
import { WebBankAccountHelpers } from '../../data-helpers/WebBankAccountHelpers';
import { WebCompanyHelpers } from '../../data-helpers/WebCompanyHelpers';
import { WebDocumentHelpers } from '../../data-helpers/WebDocumentHelpers';
import { WebOnboardingHelpers } from '../../data-helpers/WebOnboardingHelpers';
import { WebOnboardingInviteHelpers } from '../../data-helpers/WebOnboardingInviteHelpers';
import { WebPaymentRequestDetailHelpers } from '../../data-helpers/WebPaymentRequestDetailHelpers';
import { WebSupplierCompanyHelpers } from '../../data-helpers/WebSupplierCompanyHelpers';
import { WebSupplierPaymentRequestHelpers } from '../../data-helpers/WebSupplierPaymentRequestHelpers';
import { WebSupplierReceivableHelpers } from '../../data-helpers/WebSupplierReceivableHelpers';
import { WebUserHelpers } from '../../data-helpers/WebUserHelpers';
import { safeParseMaskedInput } from '../../helpers/safe-parse';
import { AddRetailerForm, DevToolsState } from '../../types';
import { appActions } from '../app/app-slice';
import { AppState } from '../app/app.types';
import { companySelectActions } from '../company-select/company-select-slice';
import { notifierActions } from '../notifier/notifier-slice';
import { calcFirstAvailableDate } from './FornecedorAgendas/payment-hooks';
import { supplierActions } from './supplier.slice';
import {
  LoadReceivablesRequest,
  LoadRetailerSettlementsRequest,
  LoadSelectedPaymentRequestRequest,
  MarkedConciliation,
  SupplierState,
} from './supplier.types';

// const_pause = async (timeout: number) =>
//   new Promise((res) => setTimeout(res, timeout));

function* watchLoadRetailers() {
  while (true) {
    yield take(supplierActions.loadRetailers);
    yield loadRetailers();
  }
}

function* loadRetailers() {
  const { company }: AppState = yield select((state) => state.app);
  try {
    yield put(supplierActions.setIsLoadingRetailers(true));
    if (!company?.id) throw new Error('Company ID not found');
    const companies: Models.Company[] =
      yield WebSupplierCompanyHelpers.listRetailers();
    yield put(supplierActions.setRetailers(companies));
    const invites: Models.OnboardingInvite[] =
      yield WebOnboardingInviteHelpers.list('idSupplier', company?.id);
    yield put(supplierActions.setInvites(invites));
    yield put(supplierActions.setIsLoadingRetailers(false));
  } catch (e) {
    yield put(appActions.setError((e as Error).message));
    yield put(supplierActions.setIsLoadingRetailers(false));
  }
}

// function* watchLoadInvites() {
//   while (true) {
//     yield take(supplierActions.loadInvites);
//     yield put(supplierActions.setIsLoading(true));
//     const { company }: AppState = yield select((state) => state.app);
//     try {
//       if (!company?.id) throw new Error('Company ID not found');
//       const invites: Models.OnboardingInvite[] =
//         yield OnboardingInviteHelpers.list('idSupplier', company?.id);
//       yield put(supplierActions.setInvites(invites));
//       yield put(supplierActions.setIsLoading(false));
//     } catch (e) {
//       yield put(appActions.setError((e as Error).message));
//       yield put(supplierActions.setIsLoading(false));
//     }
//   }
// }

function* watchAddRetailer() {
  while (true) {
    const {
      payload: { data, navigate, optinFile },
    }: PayloadAction<{
      data: AddRetailerForm;
      optinFile: File;
      navigate: NavigateFunction;
    }> = yield take(supplierActions.addRetailer);
    yield put(supplierActions.setIsSavingRetailer(true));
    const { company }: AppState = yield select((state) => state.app);
    const { skipRegistrar }: DevToolsState = yield select(
      (state) => state.devTools
    );
    try {
      if (!company?.id) throw new Error('Company ID not found');
      let docIdOptin = '';
      if (optinFile) {
        docIdOptin = yield WebDocumentHelpers.upload(optinFile);
      }
      yield WebOnboardingHelpers.createRetailer({
        supplierDocNumber: company.docNumber,
        inviteEmail: data.email,
        company: {
          companyType: 'retailer',
          docNumber: data.docNumber,
          name: data.name,
          fantasy: data.fantasy,
          onboardingComplete: false,
          idSupplier: company.id,
          mainContactName: data.mainContactName,
          mainContactEmail: data.email,
          gender: data.gender,
        },
        optin: {
          signatureDate: dayjs().format('YYYY-MM-DD'),
          startDate: dayjs().format('YYYY-MM-DD'),
          endDate: dayjs().add(3, 'months').format('YYYY-MM-DD'),
          docIdOptin,
        },
        skipRegistrar,
      });

      yield put(
        notifierActions.enqueue({
          message: `Cliente adicionado! Convite enviado para ${data.email}`,
          options: {
            variant: 'success',
          },
        })
      );
      yield put(companySelectActions.loadCompanies());
      yield put(supplierActions.setIsSavingRetailer(false));
      yield navigate('/');
    } catch (e) {
      let message: string | undefined;
      const eAny = e as unknown as any;
      if (eAny.response && eAny.response.json) {
        const responseData: { message: string } = yield eAny.response.json();
        message = responseData.message;
      }
      yield put(appActions.setError(message ?? (e as Error).message));
      yield put(supplierActions.setIsSavingRetailer(false));
    }
  }
}

function* loadSelectedRetailer(idRetailer: string) {
  const { selectedRetailer }: SupplierState = yield select(
    (state) => state.supplier
  );
  try {
    if (!selectedRetailer || selectedRetailer.id !== idRetailer) {
      yield put(supplierActions.setIsLoadingSelectedRetailer(true));
      const company: Models.Company = yield WebCompanyHelpers.getById(
        idRetailer
      );
      yield put(supplierActions.setSelectedRetailer(company));
      if (company.idOwner) {
        const user: Models.User = yield WebUserHelpers.getById(company.idOwner);
        yield put(supplierActions.setSelectedRetailerOwner(user));
      }
      yield put(supplierActions.setIsLoadingSelectedRetailer(false));
    }
  } catch (e) {
    yield put(supplierActions.setIsLoadingSelectedRetailer(false));
    throw e;
  }
}

function* loadSelectedRetailerBankAccount(idRetailer: string) {
  yield put(supplierActions.setIsLoadingSelectedRetailerBankAccount(true));
  try {
    const bankAccount: Models.BankAccount =
      yield WebBankAccountHelpers.getByRetailerId(idRetailer);
    yield put(supplierActions.setSelectedRetailerBankAccount(bankAccount));
    yield put(supplierActions.setIsLoadingSelectedRetailerBankAccount(false));
  } catch (error) {
    yield put(supplierActions.setSelectedRetailerBankAccount(undefined));
    yield put(supplierActions.setIsLoadingSelectedRetailerBankAccount(false));
  }
}

function* watchLoadReceivables() {
  while (true) {
    const {
      payload: { idRetailer, startDate, endDate },
    }: PayloadAction<LoadReceivablesRequest> = yield take(
      supplierActions.loadReceivables
    );
    yield put(supplierActions.setIsLoadingReceivables(true));
    try {
      yield fork(loadSelectedRetailer, idRetailer);
      const receivables: Models.Receivable[] =
        yield WebSupplierReceivableHelpers.listByRetailer(
          startDate,
          endDate,
          idRetailer
        );

      yield put(supplierActions.setRetailerReceivables(receivables));
      const retailerPaymentRequests: Models.PaymentRequest[] =
        yield WebSupplierPaymentRequestHelpers.list({
          dateType: 'firstIncomingDate',
          startDate: '19700101',
          endDate: '21991231',
          statuses: ['pendente'],
          idRetailer,
        });
      yield put(supplierActions.setPaymentRequests(retailerPaymentRequests));
      const retailerPaymentRequestDetails: Models.PaymentRequestDetail[] =
        yield WebPaymentRequestDetailHelpers.list(
          [],
          '19700101',
          '21991231',
          'idRetailer',
          idRetailer
        );
      yield put(
        supplierActions.setPaymentRequestDetails(retailerPaymentRequestDetails)
      );

      yield put(supplierActions.setIsLoadingReceivables(false));
    } catch (e) {
      yield put(appActions.setError((e as Error).message));
      yield put(supplierActions.setIsLoadingReceivables(false));
    }
  }
}

function* watchLoadRetailerReceivableDetails() {
  while (true) {
    const {
      payload: { idRetailer, startDate, endDate },
    }: PayloadAction<LoadReceivablesRequest> = yield take(
      supplierActions.loadRetailerReceivables
    );
    yield put(supplierActions.setIsLoadingReceivables(true));
    try {
      yield fork(loadSelectedRetailer, idRetailer);
      const receivables: Models.Receivable[] =
        yield WebSupplierReceivableHelpers.listByRetailer(
          startDate,
          endDate,
          idRetailer
        );
      yield put(
        supplierActions.setRetailerReceivables(
          receivables.map((r) => ({ ...r, suggestedValue: 0 }))
        )
      );
      yield put(supplierActions.setIsLoadingReceivables(false));
    } catch (e) {
      yield put(appActions.setError((e as Error).message));
      yield put(supplierActions.setIsLoadingReceivables(false));
    }
  }
}

function* watchPaymentRequestRealtimeData(idPaymentRequest: string) {
  const chan: EventChannel<Models.PaymentRequestRealtimeData> = yield call(
    {
      context: null,
      fn: WebSupplierPaymentRequestHelpers.createRealtimeChannel,
    },
    idPaymentRequest
  );
  try {
    console.log('Watching payment request ', idPaymentRequest);
    while (true) {
      const { status }: Models.PaymentRequestRealtimeData = yield take(chan);

      console.log('Watching payment request - got update: ', status);

      const { selectedPaymentRequest }: SupplierState = yield select(
        (state) => state.supplier
      );

      if (status && selectedPaymentRequest)
        yield put(
          supplierActions.setSelectedPaymentRequest({
            ...selectedPaymentRequest,
            status,
          })
        );
    }
  } finally {
    const isCancelled: CancelledEffect = yield cancelled();
    if (isCancelled) {
      chan.close();
      console.log('Closed updates channel');
    }
  }
}

function* watchLoadSelectedPaymentRequest() {
  while (true) {
    const {
      payload: { idRetailer, idPaymentRequest },
    }: PayloadAction<LoadSelectedPaymentRequestRequest> = yield take(
      supplierActions.loadSelectedPaymentRequest
    );
    yield put(supplierActions.setIsLoadingSelectedPaymentRequest(true));
    try {
      yield fork(loadSelectedRetailer, idRetailer);
      yield fork(loadSelectedRetailerBankAccount, idRetailer);

      const paymentRequest: Models.PaymentRequest =
        yield WebSupplierPaymentRequestHelpers.getById(idPaymentRequest);
      let confirmedByName: string | undefined = undefined;
      let confirmedByEmail: string | undefined = undefined;
      let rejectedByName: string | undefined = undefined;
      let rejectedByEmail: string | undefined = undefined;
      if (paymentRequest.confirmedBy) {
        const user: Models.User = yield WebUserHelpers.getById(
          paymentRequest.confirmedBy
        );
        confirmedByName = user.name;
        confirmedByEmail = user.email;
      }
      if (paymentRequest.rejectedBy) {
        const user: Models.User = yield WebUserHelpers.getById(
          paymentRequest.rejectedBy
        );
        rejectedByName = user.name;
        rejectedByEmail = user.email;
      }
      yield put(
        supplierActions.setSelectedPaymentRequest({
          ...paymentRequest,
          confirmedByName,
          confirmedByEmail,
          rejectedByName,
          rejectedByEmail,
        })
      );

      const watchRealtimeDataTask: Task = yield fork(
        watchPaymentRequestRealtimeData,
        idPaymentRequest
      );
      const details: Models.PaymentRequestDetail[] =
        yield WebPaymentRequestDetailHelpers.list(
          [],
          '19700101',
          '21991231',
          'idPaymentRequest',
          idPaymentRequest
        );
      yield put(supplierActions.setSelectedPaymentRequestDetails(details));
      yield put(supplierActions.setIsLoadingSelectedPaymentRequest(false));

      yield take(appActions.changeLocation);
      yield cancel(watchRealtimeDataTask);
    } catch (e) {
      yield put(appActions.setError((e as Error).message));
      yield put(supplierActions.setIsLoadingSelectedPaymentRequest(false));
    }
  }
}

function* watchLoadSelectedPaymentRequestDetails() {
  while (true) {
    const {
      payload: { idPaymentRequest },
    }: PayloadAction<LoadSelectedPaymentRequestRequest> = yield take(
      supplierActions.loadSelectedPaymentRequestDetails
    );
    try {
      const details: Models.PaymentRequestDetail[] =
        yield WebPaymentRequestDetailHelpers.list(
          [],
          '19700101',
          '21991231',
          'idPaymentRequest',
          idPaymentRequest
        );
      yield put(supplierActions.setSelectedPaymentRequestDetails(details));
    } catch (e) {
      yield put(appActions.setError((e as Error).message));
    }
  }
}

function* watchRefreshSelectedPaymentRequest() {
  while (true) {
    const { payload: idPaymentRequest }: PayloadAction<string> = yield take(
      supplierActions.refreshSelectedPaymentRequest
    );
    try {
      yield WebSupplierPaymentRequestHelpers.refresh(idPaymentRequest);
    } catch (e) {
      yield put(appActions.setError((e as Error).message));
    }
  }
}

function* watchLoadPaymentRequests() {
  while (true) {
    const {
      payload,
    }: PayloadAction<Models.LoadPaymentRequestsForSupplierRequest> = yield take(
      supplierActions.loadPaymentRequests
    );
    yield put(supplierActions.setIsLoadingPaymentRequests(true));
    try {
      yield fork(loadRetailers);
      const paymentRequests: Models.PaymentRequest[] =
        yield WebSupplierPaymentRequestHelpers.list(payload);
      yield put(supplierActions.setPaymentRequests(paymentRequests));
      yield put(supplierActions.setIsLoadingPaymentRequests(false));
    } catch (e) {
      yield put(appActions.setError((e as Error).message));
      yield put(supplierActions.setIsLoadingPaymentRequests(false));
    }
  }
}

function* watchCreatePaymentRequest() {
  while (true) {
    const {
      payload: { navigate },
    }: PayloadAction<{
      navigate: NavigateFunction;
    }> = yield take(supplierActions.createPaymentRequest);
    try {
      yield put(supplierActions.setIsCreatingPaymentRequest(true));
      const { selectedRetailer, paymentFormData }: SupplierState = yield select(
        (state) => state.supplier
      );
      const { company }: AppState = yield select((state) => state.app);
      if (!selectedRetailer || !company || !paymentFormData)
        throw new Error('Unexpected error');

      const idempotencyKey = uuid();

      const receivables: Models.WebPaymentRequestReceivable[] = Object.values(
        paymentFormData.details
      ).map((d) => ({
        idReceivable: d.idReceivable,
        value: safeParseMaskedInput(d.value),
      }));

      const payload: Models.WebPaymentRequest = {
        idRetailer: selectedRetailer.id,
        retailerDocNumber: selectedRetailer.docNumber,
        idempotencyKey,
        effectType: paymentFormData.effectType,
        externalId: paymentFormData.externalId,
        receivables,
        dueDate: dayjs(paymentFormData.paymentDueDate).format('YYYYMMDD'),
        originalValue: safeParseMaskedInput(paymentFormData.originalValue),
      };

      // console.log('payload...', payload);
      const created: Models.PaymentRequest =
        yield WebSupplierPaymentRequestHelpers.create(payload);

      yield put(supplierActions.setSelectedPaymentRequest(created));

      if (paymentFormData.requireRetailerApproval)
        yield put(supplierActions.setIsCreatePaymentRequestSuccessOpen(true));
      else {
        yield put(
          notifierActions.enqueue({
            message: 'Solicitação de pagamento criada com sucesso.',
            options: {
              variant: 'success',
            },
          })
        );
        yield navigate(`/pagamentos/${selectedRetailer.id}/${created.id}`);
      }
      yield put(supplierActions.setIsCreatingPaymentRequest(false));
    } catch (e) {
      yield put(appActions.setError((e as Error).message));
      yield put(supplierActions.setIsCreatingPaymentRequest(false));
    }
  }
}

function* watchLoadRetailerSettlements() {
  while (true) {
    const {
      payload: { idRetailer, startDate, endDate },
    }: PayloadAction<LoadRetailerSettlementsRequest> = yield take(
      supplierActions.loadRetailerSettlements
    );
    yield put(supplierActions.setIsLoadingRetailerSettlements(true));
    try {
      yield fork(loadSelectedRetailer, idRetailer);
      const retailerPaymentRequestDetails: Models.PaymentRequestDetail[] =
        yield WebPaymentRequestDetailHelpers.list(
          [],
          startDate,
          endDate,
          'idRetailer',
          idRetailer
        );
      yield put(
        supplierActions.setPaymentRequestDetails(retailerPaymentRequestDetails)
      );
      const retailerPaymentRequests: Models.PaymentRequest[] =
        yield WebSupplierPaymentRequestHelpers.list({
          dateType: 'firstIncomingDate',
          startDate: '19700101',
          endDate: '21991231',
          statuses: ['ativo', 'finalizado'],
          idRetailer,
        });
      yield put(supplierActions.setPaymentRequests(retailerPaymentRequests));
      yield put(supplierActions.setIsLoadingRetailerSettlements(false));
    } catch (e) {
      yield put(appActions.setError((e as Error).message));
      yield put(supplierActions.setIsLoadingRetailerSettlements(false));
    }
  }
}

function* watchLoadSettlements() {
  while (true) {
    const {
      payload,
    }: PayloadAction<Models.LoadPaymentRequestDetailsForSupplierRequest> =
      yield take(supplierActions.loadSettlements);
    yield put(supplierActions.setIsLoadingSettlements(true));
    try {
      yield fork(loadRetailers);
      const retailerPaymentRequestDetails: Models.PaymentRequestDetail[] =
        yield WebPaymentRequestDetailHelpers.listForSupplier(payload);
      yield put(
        supplierActions.setPaymentRequestDetails(retailerPaymentRequestDetails)
      );
      const paymentRequests: Models.PaymentRequest[] =
        yield WebSupplierPaymentRequestHelpers.list({
          statuses: ['ativo'],
          dateType: 'firstIncomingDate',
          startDate: '19700101',
          endDate: '21991231',
        });
      yield put(supplierActions.setPaymentRequests(paymentRequests));

      yield put(supplierActions.setIsLoadingSettlements(false));
    } catch (e) {
      yield put(appActions.setError((e as Error).message));
      yield put(supplierActions.setIsLoadingSettlements(false));
    }
  }
}

function* loadRetailerBankAccountTransactions(
  payload: Models.ListRetailerBankAccountTransactionsRequest
) {
  yield put(supplierActions.setIsLoadingRetailerBankAccountTransactions(true));
  const rpcCallResult: RPC.Call<
    void,
    Models.ListBankAccountTransactionResponse
  > = yield WebBankAccountHelpers.listRetailerTransactions(payload);
  const { result, resultPayload, error } = rpcCallResult;

  if (result === 'success') {
    if (!resultPayload) throw new Error('Unexpected empty payload');
    yield put(
      supplierActions.setRetailerBankAccountTransactions(resultPayload)
    );
    yield put(
      supplierActions.setIsLoadingRetailerBankAccountTransactions(false)
    );
  } else {
    yield put(appActions.setError(error ?? 'Error'));
    yield put(
      supplierActions.setIsLoadingRetailerBankAccountTransactions(false)
    );
  }
}

function* loadRetailerTransactionConciliations(
  payload: Models.ListRetailerBankAccountTransactionsRequest
) {
  const transactions: Models.FinancialTransaction[] =
    yield WebBankAccountHelpers.listRetailerTransactionConciliations(payload);

  yield put(supplierActions.setRetailerTransactionConciliations(transactions));
}

function* watchLoadRetailerSettlementsForConciliation() {
  while (true) {
    yield take(supplierActions.loadRetailerSettlementsForConciliation);

    try {
      yield put(
        supplierActions.setIsLoadingRetailerSettlementsForConciliation(true)
      );
      const { selectedRetailer }: SupplierState = yield select(
        (state) => state.supplier
      );
      if (!selectedRetailer)
        throw new Error('Unexepected empty selected Retailer');
      const retailerPaymentRequestDetails: Models.PaymentRequestDetail[] =
        yield WebPaymentRequestDetailHelpers.list(
          ['aguardando_liquidacao'],
          '19700101',
          '21991231',
          'idRetailer',
          selectedRetailer.id
        );
      yield put(
        supplierActions.setPaymentRequestDetails(retailerPaymentRequestDetails)
      );
      const retailerPaymentRequests: Models.PaymentRequest[] =
        yield WebSupplierPaymentRequestHelpers.list({
          dateType: 'firstIncomingDate',
          startDate: '19700101',
          endDate: '21991231',
          statuses: ['ativo', 'finalizado'],
          idRetailer: selectedRetailer.id,
        });
      yield put(supplierActions.setPaymentRequests(retailerPaymentRequests));
      yield put(
        supplierActions.setIsLoadingRetailerSettlementsForConciliation(false)
      );
    } catch (e) {
      yield put(appActions.setError((e as Error).message));
      yield put(
        supplierActions.setIsLoadingRetailerSettlementsForConciliation(false)
      );
    }
  }
}

function* watchLoadRetailerBankAccountTransactions() {
  while (true) {
    const {
      payload,
    }: PayloadAction<Models.ListRetailerBankAccountTransactionsRequest> =
      yield take(supplierActions.loadRetailerBankAccountTransactions);
    yield fork(loadSelectedRetailer, payload.idRetailer);
    yield fork(loadSelectedRetailerBankAccount, payload.idRetailer);
    yield fork(loadRetailerBankAccountTransactions, payload);
    yield fork(loadRetailerTransactionConciliations, payload);
  }
}

function* watchLoadRetailerBankAccount() {
  while (true) {
    const { payload: idRetailer }: PayloadAction<string> = yield take(
      supplierActions.loadRetailerBankAccount
    );
    yield fork(loadSelectedRetailerBankAccount, idRetailer);
  }
}

function* watchSaveConciliationResult() {
  while (true) {
    const {
      payload: { data, navigate, currentUrl },
    }: PayloadAction<{
      data: MarkedConciliation[];
      navigate: NavigateFunction;
      currentUrl: string;
    }> = yield take(supplierActions.saveConciliationResult);

    try {
      yield put(supplierActions.setIsSavingRetailerConciliation(true));
      const { selectedRetailer }: SupplierState = yield select(
        (state) => state.supplier
      );
      if (!selectedRetailer)
        throw new Error('Unexepected empty selected Retailer');

      const flattenedTransactions = data
        .map((conciliation) => {
          return conciliation.transactions.map((transaction) => {
            return {
              transaction,
              settlements: conciliation.settlements,
              status: conciliation.status,
            };
          });
        })
        .flat();

      const request: Models.SaveConciliationResultRequest = {
        idRetailer: selectedRetailer.id,
        transactions: flattenedTransactions.map((t) => ({
          value: t.transaction.valor,
          transactionId: t.transaction.id,
          description: t.transaction.detalhe,
          transactionDate: t.transaction.data,
          conciliationStatus: t.status,
          conciliationDetail: t.settlements.map((s) => ({
            idPaymentRequestDetail: s.id,
            idPaymentRequest: s.idPaymentRequest,
            externalId: s.idPedido,
            value: s.valor,
          })),
        })),
      };

      yield WebBankAccountHelpers.saveConciliationResult(request);
      yield put(supplierActions.setIsRetailerConciliationPanelOpen(false));
      yield put(supplierActions.setIsSavingRetailerConciliation(false));
      yield put(
        notifierActions.enqueue({
          message: 'A conciliação foi salva com sucesso.',
          options: {
            variant: 'success',
          },
        })
      );
      yield navigate(currentUrl);
    } catch (e) {
      yield put(appActions.setError((e as Error).message));
      yield put(supplierActions.setIsSavingRetailerConciliation(false));
    }
  }
}

function* watchCancelPaymentRequest() {
  while (true) {
    const {
      payload: {
        request: { idRetailer, idPaymentRequest },
      },
    }: PayloadAction<{
      request: Omit<Models.WebCancelPaymentRequestPayload, 'idempotencyKey'>;
      navigate: NavigateFunction;
    }> = yield take(supplierActions.cancelPaymentRequest);
    const idempotencyKey = uuid();
    const payload: Models.WebCancelPaymentRequestPayload = {
      idempotencyKey,
      idRetailer,
      idPaymentRequest,
    };

    try {
      yield put(supplierActions.setIsCancelingPaymentRequest(true));
      yield WebSupplierPaymentRequestHelpers.cancel(payload);
      yield put(
        notifierActions.enqueue({
          message: 'Solicitação de cancelamento efetuada com sucesso.',
          options: {
            variant: 'success',
          },
        })
      );
      yield put(supplierActions.setIsCancelingPaymentRequest(false));
    } catch (e) {
      yield put(appActions.setError((e as Error).message));
      yield put(supplierActions.setIsCancelingPaymentRequest(false));
    }
  }
}

function* watchArchivePaymentRequest() {
  while (true) {
    const {
      payload: {
        request: { idRetailer, idPaymentRequest },
        navigate,
      },
    }: PayloadAction<{
      request: Omit<Models.WebArchivePaymentRequestPayload, 'idempotencyKey'>;
      navigate: NavigateFunction;
    }> = yield take(supplierActions.archivePaymentRequest);
    const idempotencyKey = uuid();
    const payload: Models.WebArchivePaymentRequestPayload = {
      idempotencyKey,
      idRetailer,
      idPaymentRequest,
    };

    try {
      yield put(supplierActions.setIsArchivingPaymentRequest(true));
      yield WebSupplierPaymentRequestHelpers.archive(payload);
      yield put(
        notifierActions.enqueue({
          message: 'Pagamento arquivado com sucesso.',
          options: {
            variant: 'success',
          },
        })
      );
      yield put(supplierActions.setIsArchivingPaymentRequest(false));
      yield navigate(`/pagamentos/${idRetailer}/${idPaymentRequest}`);
    } catch (e) {
      yield put(appActions.setError((e as Error).message));
      yield put(supplierActions.setIsArchivingPaymentRequest(false));
    }
  }
}

function* watchDeletePaymentRequest() {
  while (true) {
    const {
      payload: { idPaymentRequest, navigate, idRetailer },
    }: PayloadAction<{
      idPaymentRequest: string;
      idRetailer: string;
      navigate: NavigateFunction;
    }> = yield take(supplierActions.deletePaymentRequest);
    try {
      yield put(supplierActions.setIsDeletingPaymentRequest(true));
      yield WebSupplierPaymentRequestHelpers.delete(idPaymentRequest);
      yield put(
        notifierActions.enqueue({
          message: 'Pagamento excluído com sucesso.',
          options: {
            variant: 'success',
          },
        })
      );
      yield put(supplierActions.setIsDeletingPaymentRequest(false));
      yield navigate(`/pagamentos?idRetailer=${idRetailer}`);
    } catch (e) {
      yield put(appActions.setError((e as Error).message));
      yield put(supplierActions.setIsDeletingPaymentRequest(false));
    }
  }
}

function* loadSupplierBankAccountTransactions(
  payload: Models.ListSupplierBankAccountTransactionsRequest
) {
  try {
    yield put(
      supplierActions.setIsLoadingSupplierBankAccountTransactions(true)
    );
    const rpcCallResult: RPC.Call<
      void,
      Models.ListBankAccountTransactionResponse
    > = yield WebBankAccountHelpers.listSupplierTransactions(payload);
    const { result, resultPayload, error } = rpcCallResult;

    if (result === 'success') {
      if (!resultPayload) throw new Error('Unexpected empty payload');
      yield put(
        supplierActions.setSupplierBankAccountTransactions(resultPayload)
      );
      yield put(
        supplierActions.setIsLoadingSupplierBankAccountTransactions(false)
      );
    } else {
      yield put(appActions.setError(error ?? 'Error'));
      yield put(
        supplierActions.setIsLoadingSupplierBankAccountTransactions(false)
      );
    }
  } catch (e) {
    yield put(appActions.setError((e as Error).message));
    yield put(
      supplierActions.setIsLoadingSupplierBankAccountTransactions(false)
    );
  }
}

function* loadSupplierTransactionConciliations(
  payload: Models.ListSupplierBankAccountTransactionsRequest
) {
  try {
    yield put(
      supplierActions.setIsLoadingSupplierTransactionConciliations(true)
    );
    const transactions: Models.FinancialTransaction[] =
      yield WebBankAccountHelpers.listSupplierTransactionConciliations(payload);

    yield put(
      supplierActions.setSupplierTransactionConciliations(transactions)
    );
    yield put(
      supplierActions.setIsLoadingSupplierTransactionConciliations(false)
    );
  } catch (e) {
    yield put(appActions.setError((e as Error).message));
    yield put(
      supplierActions.setIsLoadingSupplierTransactionConciliations(false)
    );
  }
}

function* watchLoadSupplierBankAccountTransactions() {
  while (true) {
    const {
      payload,
    }: PayloadAction<Models.ListSupplierBankAccountTransactionsRequest> =
      yield take(supplierActions.loadSupplierBankAccountTransactions);
    // yield fork(loadSelectedSupplier, payload.idSupplier);
    // yield fork(loadSelectedSupplierBankAccount, payload.idSupplier);
    yield fork(loadSupplierBankAccountTransactions, payload);
    yield fork(loadSupplierTransactionConciliations, payload);
  }
}

function* watchCalcSuggestedValues() {
  while (true) {
    try {
      const {
        payload: { opts, navigate },
      }: PayloadAction<{
        opts: Supplier.CalcBalanceOpts;
        navigate: NavigateFunction;
      }> = yield take(supplierActions.calcSuggestedValues);
      yield put(supplierActions.setIsRunningCalcSuggestedValues(true));
      yield put(supplierActions.setRetailerCalcSuggestedValues([]));
      const result: Supplier.CalcBalanceResult =
        yield WebSupplierPaymentRequestHelpers.calcSuggestedValues(opts);
      yield put(supplierActions.setRetailerCalcSuggestedValues(result));
      yield put(supplierActions.setIsRunningCalcSuggestedValues(false));

      const { supplierWarrantyConfig, supplierOwnershipAssignmentConfig } =
        yield select((state) => state.settings);

      const initialStartDate = calcFirstAvailableDate(
        supplierWarrantyConfig,
        supplierOwnershipAssignmentConfig,
        opts.effectType,
        opts.paymentDueDate
      ).format('YYYYMMDD');
      const initialEndDate = dayjs(initialStartDate)
        .add(1, 'month')
        .format('YYYYMMDD');

      const { startDate, endDate } = result.reduce(
        (acc, cur) => {
          if (dayjs(cur.incomingDate).isBefore(dayjs(acc.startDate)))
            acc.startDate = cur.incomingDate;
          if (dayjs(cur.incomingDate).isAfter(dayjs(acc.endDate)))
            acc.endDate = cur.incomingDate;
          return acc;
        },
        { startDate: initialStartDate, endDate: initialEndDate }
      );
      navigate(
        `/clientes/${
          opts.idRetailer
        }/agenda?s=${startDate}&e=${endDate}&externalId=${encodeURIComponent(
          opts.externalId
        )}&originalValue=${encodeURIComponent(
          opts.originalValue
        )}&paymentDueDate=${opts.paymentDueDate}&effectType=${
          opts.effectType
        }&x=`
      );
    } catch (e) {
      yield put(appActions.setError((e as Error).message));
      yield put(supplierActions.setIsRunningCalcSuggestedValues(false));
    }
  }
}

export default [
  watchLoadRetailers,
  watchAddRetailer,
  watchLoadReceivables,
  watchLoadRetailerReceivableDetails,
  watchLoadPaymentRequests,
  watchCreatePaymentRequest,
  watchLoadSelectedPaymentRequest,
  watchLoadSelectedPaymentRequestDetails,
  watchRefreshSelectedPaymentRequest,
  watchLoadSettlements,
  watchLoadRetailerSettlements,
  watchLoadRetailerBankAccountTransactions,
  watchLoadRetailerBankAccount,
  watchSaveConciliationResult,
  watchLoadRetailerSettlementsForConciliation,
  watchCancelPaymentRequest,
  watchArchivePaymentRequest,
  watchDeletePaymentRequest,
  watchLoadSupplierBankAccountTransactions,
  watchCalcSuggestedValues,
];
