import LaunchIcon from '@mui/icons-material/Launch';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import {
  Box,
  Chip,
  CircularProgress,
  Link,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import React, { MouseEvent, useEffect, useState } from 'react';
import { Models } from 'types';

import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { gridClasses } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { Constants } from 'pr-constants';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { ConciliationStatus } from '../../../components/ConciliationStatus/ConciliationStatus';
import FilterForm from '../../../components/FilterForm/FilterForm';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import DataTable from '../../../components/Table/DataTable';
import { TableColumn } from '../../../components/Table/table.types';
import { getFirestoreConsoleLink } from '../../../helpers/firebase';
import { format } from '../../../helpers/format';
import { useAppDispatch, useAppSelector } from '../../../helpers/hooks';
import useUserGrant from '../../../hooks/useUserGrant';
import { ExtratoFilterForm } from '../../../types';
import { appActions } from '../../app/app-slice';
import { bankAccountActions } from '../../bank-account/bank-account-slice';
import { supplierActions } from '../supplier.slice';
import { ExtratoFornecedor } from '../supplier.types';

type DetailProps = {
  label: string;
  value?: string;
  children?: React.ReactNode;
};
const Detail = ({ label, value, children }: DetailProps) => (
  <Box>
    <Typography variant="body2" color="textSecondary" component="p">
      {label}:
    </Typography>
    {value && (
      <Typography variant="body1" color="textPrimary" component="p">
        {' '}
        {value ?? '-'}
      </Typography>
    )}
    {children}
  </Box>
);

function FornecedorContaConsolidadora() {
  const [isBalanceVisible, setIsBalanceVisible] = useState(false);

  const navigate = useNavigate();
  const { company, bankAccount } = useAppSelector((state) => state.app);
  const {
    isLoadingSupplierBankAccountTransactions,
    isLoadingSupplierTransactionConciliations,
    supplierBankAccountTransactions,
    supplierTransactionConciliations,
  } = useAppSelector((state) => state.supplier);
  const { bankAccountInfo, isLoadingBankAccountInfo } = useAppSelector(
    (state) => state.bankAccount
  );
  const { isDebugActivated } = useAppSelector((state) => state.devTools);
  const dispatch = useAppDispatch();
  const [params] = useSearchParams();
  const startDate = params.get('s');
  const endDate = params.get('e');
  const defaultStartDate = dayjs().startOf('month');
  const defaultEndDate = dayjs();
  const muiTheme = useTheme();
  const dateExpression = params.get('x') ?? undefined;
  const queryParams: ExtratoFilterForm = {
    startDate: startDate ?? defaultStartDate.format('YYYYMMDD'),
    endDate: endDate ?? defaultEndDate.format('YYYYMMDD'),
  };

  const currentDateRangeLabel =
    queryParams.startDate !== queryParams.endDate
      ? `${dayjs(queryParams.startDate).format('DD/MM/YYYY')} a ${dayjs(
          queryParams.endDate
        ).format('DD/MM/YYYY')}`
      : dayjs(queryParams.startDate).format('DD/MM/YYYY');
  const currentDateRangeFilter = `Data: ${currentDateRangeLabel}`;

  const isBankAccountReady = bankAccount?.bankStatus === 'account_opened';

  useEffect(() => {
    if (company?.id) {
      dispatch(
        supplierActions.loadSupplierBankAccountTransactions({
          startDate: queryParams.startDate,
          endDate: queryParams.endDate,
          idSupplier: company.id,
        })
      );
    }
    if (bankAccount && isBankAccountReady) {
      dispatch(bankAccountActions.getSupplierAccountInfo());
    }
  }, []);

  const showBankAccount = useUserGrant(['supplier-bankaccount']);

  const handleBankAccountClick = () => {
    dispatch(appActions.setIsBankAccountPanelOpen(true));
  };

  const navigateWithFilter = (
    destination: string,
    { dateExpression, startDate, endDate }: ExtratoFilterForm
  ) => {
    const dateFilter = `?s=${startDate}&e=${endDate}`;
    const dateExpressionFilter = dateExpression ? `&x=${dateExpression}` : '';
    navigate(`${destination}${dateFilter}${dateExpressionFilter}`);
  };

  const handleFilter = (data: ExtratoFilterForm) => {
    navigateWithFilter('.', {
      dateExpression:
        data.dateExpression as Models.LoadPaymentRequestsForSupplierRequestDateExpression,
      startDate: dayjs(data.startDate).format('YYYYMMDD'),
      endDate: dayjs(data.endDate).format('YYYYMMDD'),
    });
  };

  const handleResetFilter = () => {
    navigateWithFilter('.', {
      startDate: queryParams.startDate,
      endDate: queryParams.endDate,
      dateExpression,
    });
  };

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleFilterChipClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const columns: TableColumn<ExtratoFornecedor>[] = [
    { id: 'id', label: 'id', hidden: !isDebugActivated },
    {
      id: 'internalId',
      label: 'internalId',
      hidden: !isDebugActivated,
      renderer: (v) => (
        <Link
          target="_blank"
          rel="noopener noreferrer"
          variant="caption"
          color="textSecondary"
          href={getFirestoreConsoleLink(`/financial-transactions/${v}`)}
        >
          {v}
        </Link>
      ),
    },
    { id: 'data', label: 'Data', type: 'date', flex: 0.7 },
    { id: 'nomeCliente', label: 'Cliente', center: false },
    { id: 'tipo', label: 'Tipo', center: false },
    { id: 'detalhe', label: 'Detalhe', center: false },
    {
      id: 'idPedidos',
      label: 'Pedido',
      renderer: (v, row) => {
        if (row.idPaymentRequest && row.idRetailer)
          return (
            <>
              {v}
              <NavLink
                to={`/pagamentos/${row.idRetailer}/${row.idPaymentRequest}`}
              >
                <IconButton size="small">
                  <LaunchIcon fontSize="small" />
                </IconButton>
              </NavLink>
            </>
          );
        else return <>-</>;
      },
    },

    {
      id: 'statusConciliacao',
      label: 'Status',
      renderer: (
        status: Models.FinancialTransactionConciliationStatus | undefined
      ) =>
        isLoadingSupplierTransactionConciliations ? (
          <CircularProgress />
        ) : (
          <ConciliationStatus status={status} />
        ),
      flex: 0.3,
      center: true,
      cellClassName: 'icon',
    },
    {
      id: 'valor',
      label: 'Valor',
      type: 'money',
      flex: 0.7,
      renderer: (v) => (
        <span
          style={{
            color:
              v < 0 ? muiTheme.palette.error.main : muiTheme.palette.info.main,
          }}
        >
          {format(v, 'money')}
        </span>
      ),
    },
    { id: 'saldo', label: 'Saldo', type: 'money', flex: 0.7 },
  ];

  const enhancedData: ExtratoFornecedor[] = supplierBankAccountTransactions
    ? supplierBankAccountTransactions.data.map((d) => {
        const conciliation = (supplierTransactionConciliations ?? []).find(
          ({ transactionId }) => transactionId === d.id
        );

        const [conciliationDetail] = conciliation?.conciliationDetail ?? [];
        const idPedidos = conciliation?.conciliationDetail
          ? conciliation.conciliationDetail.map(({ externalId }) => externalId)
          : [];

        let detalhe: string;

        if (conciliation && conciliationDetail) {
          const settlementConciliationDetail =
            conciliationDetail as Models.FinancialTransactionConciliationSettlementDetail;
          if (settlementConciliationDetail.debtor) {
            detalhe = `${Constants.getDebtor(
              settlementConciliationDetail.debtor
            )} - ${Constants.getPaymentScheme(
              settlementConciliationDetail.paymentScheme
            )} - ${format(
              settlementConciliationDetail.receivableIncomingDate,
              'date'
            )} - pedidos ${idPedidos}`;
          } else {
            detalhe = conciliation.description;
          }
        } else if (conciliation) detalhe = conciliation.description;
        else detalhe = d.description;

        return {
          id: d.id,
          internalId: conciliation?.id,
          data: d.createdAt,
          tipo: d.type,
          nomeCliente:
            conciliationDetail?.retailerFantasy ??
            conciliationDetail?.retailerName,
          docCliente: conciliationDetail?.retailerDocNumber,
          detalhe,
          valor: d.value,
          saldo: d.balance,
          idPedidos,
          statusConciliacao:
            d.value > 0
              ? conciliation
                ? conciliation.conciliationStatus
                : 'aguardando_conciliacao'
              : undefined,
          idPaymentRequest: conciliationDetail?.idPaymentRequest,
          idRetailer: conciliationDetail?.idRetailer,
        };
      })
    : [];

  const accountData = `Agência ${bankAccount?.branch} - Número ${bankAccount?.account}-${bankAccount?.accountDigit}`;

  return (
    <>
      <PageHeader
        title="Conta Consolidadora"
        actions={
          <Stack direction="row" gap={2} alignItems="center">
            <Chip
              label={currentDateRangeFilter}
              onClick={handleFilterChipClick}
            />
            <FilterForm<ExtratoFilterForm>
              onFilter={handleFilter}
              onClear={handleResetFilter}
              onClose={handleFilterClose}
              anchorEl={anchorEl}
              appliedFilterCount={0}
              defaultValues={{
                startDate: queryParams.startDate,
                endDate: queryParams.endDate,
                dateExpression: dateExpression,
              }}
              fields={[
                {
                  type: 'date-range',
                  name: 'dateType',
                  startName: 'startDate',
                  endName: 'endDate',
                  formulaName: 'dateExpression',
                  label: 'Data',
                },
              ]}
            />
          </Stack>
        }
      />
      <Grid2 container paddingBottom={2}>
        <Grid2 xs={6}>
          <Stack direction="row" alignItems="center" gap={2}>
            <Detail
              label="Saldo"
              value={
                isLoadingBankAccountInfo
                  ? ''
                  : isBalanceVisible
                  ? format(bankAccountInfo?.balance, 'money')
                  : '***'
              }
            >
              {isLoadingBankAccountInfo && <CircularProgress size={15} />}
            </Detail>
            <IconButton onClick={() => setIsBalanceVisible(!isBalanceVisible)}>
              {isBalanceVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          </Stack>
        </Grid2>
        <Grid2 xs={6}>
          <Stack direction="row" alignItems="center" gap={2}>
            <Detail label="Dados" value={accountData} />
            {showBankAccount && (
              <IconButton onClick={handleBankAccountClick}>
                <LaunchIcon />
              </IconButton>
            )}
          </Stack>
        </Grid2>
      </Grid2>
      <DataTable<ExtratoFornecedor>
        columns={columns}
        data={enhancedData}
        isLoading={isLoadingSupplierBankAccountTransactions}
        sx={{
          [`& .${gridClasses.cell}.icon`]: {
            fontSize: '30px',
          },
        }}
      />
    </>
  );
}

export default FornecedorContaConsolidadora;
