import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Models } from 'types';
import { InitDBUserRequest } from '../../types';
import { AppState, OperatingHours } from './app.types';

const initialState: AppState = {
  theme: 'light',
  isInitializing: true,
  isLoading: false,
  isDrawerOpen: true,
  isProfileOpen: false,
  isSupplierSettingsOpen: false,
  isNotificationSettingsOpen: false,
  isSupplierUserSettingsOpen: false,
  isSupplierPersonalizationOpen: false,
  isDocumentsOpen: false,
  isBankAccountPanelOpen: false,
  isSupplierAPIClientsPanelOpen: false,
  onboardingPhase: 'not authenticated',
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    reset: () => initialState,
    init: () => {},
    setTheme: (state, { payload }: PayloadAction<'dark' | 'light'>) => {
      state.theme = payload;
    },
    setIsInitializing: (state, { payload }: PayloadAction<boolean>) => {
      state.isInitializing = payload;
    },
    setIsDrawerOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isDrawerOpen = payload;
    },
    setError: (_state, _action: PayloadAction<string>) => {},
    startLoading: (state) => {
      state.isLoading = true;
    },
    finishLoading: (state) => {
      state.isLoading = false;
    },
    initDBUser: (_state, _action: PayloadAction<InitDBUserRequest>) => {},
    setDBUser: (state, { payload }: PayloadAction<Models.User>) => {
      state.dbUser = payload;
    },
    setCompany: (state, { payload }: PayloadAction<Models.Company>) => {
      state.company = payload;
    },
    setSupplier: (state, { payload }: PayloadAction<Models.Company>) => {
      state.supplier = payload;
    },
    setInvite: (state, { payload }: PayloadAction<Models.OnboardingInvite>) => {
      state.invite = payload;
    },
    setOnboardingPhase: (
      state,
      { payload }: PayloadAction<Models.WebOnboardingPhase>
    ) => {
      state.onboardingPhase = payload;
    },
    changeUserCompany: (_state, _action: PayloadAction<string>) => {},
    setIsProfileOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isProfileOpen = payload;
    },
    setIsSupplierSettingsOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isSupplierSettingsOpen = payload;
    },
    setIsNotificationSettingsOpen: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isNotificationSettingsOpen = payload;
    },
    setIsSupplierUserSettingsOpen: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isSupplierUserSettingsOpen = payload;
    },
    setIsSupplierPersonalizationOpen: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isSupplierPersonalizationOpen = payload;
    },
    setIsDocumentsOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isDocumentsOpen = payload;
    },
    setIsBankAccountPanelOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isBankAccountPanelOpen = payload;
    },
    setIsSupplierAPIClientsPanelOpen: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isSupplierAPIClientsPanelOpen = payload;
    },
    setSupplierData: (
      state,
      { payload }: PayloadAction<Models.SupplierData>
    ) => {
      state.supplierData = payload;
    },
    setBankAccount: (state, { payload }: PayloadAction<Models.BankAccount>) => {
      state.bankAccount = payload;
    },
    setTargetBankAccount: (
      state,
      { payload }: PayloadAction<Models.TargetBankAccount>
    ) => {
      state.targetBankAccount = payload;
    },
    setOperatingHours: (state, { payload }: PayloadAction<OperatingHours>) => {
      state.operatingHours = payload;
    },
    setSupplierUserGroup: (
      state,
      { payload }: PayloadAction<Models.SupplierUserGroup>
    ) => {
      state.supplierUserGroup = payload;
    },
    changeLocation: () => {},
  },
});

export const appActions = appSlice.actions;

export default appSlice.reducer;
