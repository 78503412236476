import { Models } from 'types';
import { getCompanyStatus } from '../helpers/company-status';
import { useAppSelector } from '../helpers/hooks';
import { testCNPJ } from '../helpers/validations';
import useUserGrant from './useUserGrant';

const useCompanyStatus = () => {
  const { company, bankAccount, targetBankAccount } = useAppSelector(
    (state) => state.app
  );
  const { supplierWarrantyConfig, supplierOwnershipAssignmentConfig } =
    useAppSelector((state) => state.settings);

  const showDocuments = useUserGrant(['supplier-document-view']);
  const showBankAccount = useUserGrant(['supplier-bankaccount']);

  let documentsOk = false;
  const missingDocs: Models.DocType[] = [];
  const missingBankDocs: Models.DocType[] = [];
  const companyStatus = getCompanyStatus(company);
  const personType: 'legal' | 'natural' = testCNPJ(company?.docNumber ?? '')
    ? 'legal'
    : 'natural';

  if (!company) documentsOk = false;
  else if (!showDocuments) documentsOk = true;
  else {
    if (company.companyType === 'retailer') {
      documentsOk =
        ((personType === 'legal' && !!company.docIdCompanyConstitutionDoc) ||
          personType === 'natural') &&
        !!company.docIdMainContactDocPicture &&
        !!company.docIdMainContactSelfieWithDoc;
      if (!company.docIdCompanyConstitutionDoc && personType === 'legal')
        missingDocs.push('companyConstitutionDoc');
      if (!company.docIdMainContactDocPicture)
        missingDocs.push('mainContactDocPicture');
      if (!company.docIdMainContactSelfieWithDoc)
        missingDocs.push('mainContactSelfieWithDoc');
    } else {
      documentsOk =
        !!company.docIdCompanyConstitutionDoc &&
        !!company.docIdMainContactDocPicture &&
        !!company.docIdBankContract &&
        !!company.bankDocIdCompanyConstitutionDoc &&
        !!company.bankDocIdMainContactDocPicture &&
        !!company.bankDocIdBankContract;

      if (!company.docIdCompanyConstitutionDoc)
        missingDocs.push('companyConstitutionDoc');
      if (!company.docIdMainContactDocPicture)
        missingDocs.push('mainContactDocPicture');
      if (!company.docIdBankContract) missingDocs.push('bankContract');

      if (!company.bankDocIdCompanyConstitutionDoc)
        missingBankDocs.push('companyConstitutionDoc');
      if (!company.bankDocIdMainContactDocPicture)
        missingBankDocs.push('mainContactDocPicture');
      if (!company.bankDocIdBankContract) missingBankDocs.push('bankContract');
    }
  }

  const supplierSettingsOk =
    (company?.companyType === 'supplier' &&
      !!supplierWarrantyConfig &&
      !!supplierOwnershipAssignmentConfig &&
      (supplierWarrantyConfig.enabled ||
        supplierOwnershipAssignmentConfig.enabled)) ||
    company?.companyType === 'retailer';
  const profileOk = company ? companyStatus === 'Ativo' : true;
  const bankAccountOk =
    (bankAccount && bankAccount.bankStatus === 'account_opened') ||
    !showBankAccount;
  const targetBankAccountOk =
    company?.companyType === 'retailer' ||
    !showBankAccount ||
    (company?.companyType === 'supplier' && !!targetBankAccount);

  return {
    documentsOk,
    supplierSettingsOk,
    profileOk,
    bankAccountOk,
    companyStatus,
    missingDocs,
    missingBankDocs,
    targetBankAccountOk,
    personType,
  };
};
export default useCompanyStatus;
