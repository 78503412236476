import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Supplier } from 'types';
import { DevToolsState } from '../../types';

const initialState: DevToolsState = {
  isLocalApi: true,
  isDebugActivated: false,
  skipRegistrar: false,
  isUserViewActivated: false,
};

export const devToolsSlice = createSlice({
  name: 'dev-tools',
  initialState,
  reducers: {
    reset: () => initialState,
    load: () => {},
    setIsLocalApi: (state, { payload }: PayloadAction<boolean>) => {
      state.isLocalApi = payload;
    },
    setIsDebugActivated: (state, { payload }: PayloadAction<boolean>) => {
      state.isDebugActivated = payload;
    },
    setIsUserViewActivated: (state, { payload }: PayloadAction<boolean>) => {
      state.isUserViewActivated = payload;
    },
    setSkipRegistrar: (state, { payload }: PayloadAction<boolean>) => {
      state.skipRegistrar = payload;
    },
    setIdCompany: (state, { payload }: PayloadAction<string>) => {
      state.idCompany = payload;
    },
    setIdInvite: (state, { payload }: PayloadAction<string>) => {
      state.idInvite = payload;
    },
    asyncCall: (
      _state,
      _action: PayloadAction<Supplier.AsyncPostPayload>
    ) => {},
  },
});

export const devToolsActions = devToolsSlice.actions;

export default devToolsSlice.reducer;
