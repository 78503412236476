import { PayloadAction } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';
import { fork, put, select, take } from 'redux-saga/effects';
import { Models, RPC } from 'types';
import { WebBankAccountHelpers } from '../../data-helpers/WebBankAccountHelpers';
import { WebCompanyHelpers } from '../../data-helpers/WebCompanyHelpers';
import { WebDocumentHelpers } from '../../data-helpers/WebDocumentHelpers';
import { WebOnboardingHelpers } from '../../data-helpers/WebOnboardingHelpers';
import { WebOnboardingInviteHelpers } from '../../data-helpers/WebOnboardingInviteHelpers';
import { WebUserHelpers } from '../../data-helpers/WebUserHelpers';
import { appActions } from '../app/app-slice';
import { AppState } from '../app/app.types';
import { companySelectActions } from '../company-select/company-select-slice';
import { notifierActions } from '../notifier/notifier-slice';
import { adminActions } from './admin.slice';
import { AddSupplierForm, AdminState } from './admin.types';

function* watchLoadSuppliers() {
  while (true) {
    yield take(adminActions.loadSuppliers);
    yield put(adminActions.setIsLoading(true));
    try {
      const companies: Models.Company[] = yield WebCompanyHelpers.list([
        'supplier',
      ]);
      yield put(adminActions.setSuppliers(companies));
      const invites: Models.OnboardingInvite[] =
        yield WebOnboardingInviteHelpers.list();
      yield put(adminActions.setInvites(invites));
      yield put(adminActions.setIsLoading(false));
    } catch (e) {
      yield put(appActions.setError((e as Error).message));
      yield put(adminActions.setIsLoading(false));
    }
  }
}

function* watchAddSupplier() {
  while (true) {
    const {
      payload: {
        data,
        navigate,
        bankContractFile,
        repDocFile,
        companyConstitutionDocFile,
      },
    }: PayloadAction<{
      data: AddSupplierForm;
      bankContractFile?: File;
      companyConstitutionDocFile?: File;
      repDocFile?: File;
      navigate: NavigateFunction;
    }> = yield take(adminActions.addSupplier);
    yield put(adminActions.setIsLoading(true));
    const { company }: AppState = yield select((state) => state.app);
    try {
      if (!company?.id) throw new Error('Company ID not found');
      if (!bankContractFile) throw new Error('Bank contract file not found');
      if (!repDocFile) throw new Error('Rep Doc File not found');
      if (!companyConstitutionDocFile)
        throw new Error('Company constitution doc file not found');

      const docIdBankContract: string = yield WebDocumentHelpers.upload(
        bankContractFile
      );
      const docIdMainContactDocPicture: string =
        yield WebDocumentHelpers.upload(repDocFile);
      const docIdCompanyConstitutionDoc: string =
        yield WebDocumentHelpers.upload(companyConstitutionDocFile);

      yield WebOnboardingHelpers.createSupplier({
        supplierDocNumber: company.docNumber,
        inviteEmail: data.email,
        company: {
          companyType: 'supplier',
          docNumber: data.docNumber,
          name: data.name,
          fantasy: data.fantasy,
          onboardingComplete: false,
          mainContactName: data.repName,
          mainContactEmail: data.email,
          mainContactDocNumber: data.repIndividualDocumentNumber,
          mainContactPhone: data.repPhone,
          gender: data.gender,
          docIdBankContract,
          docIdCompanyConstitutionDoc,
          docIdMainContactDocPicture,
        },
        supplierData: {
          cnae: data.cnae,
          category: data.category,
          phone: data.phone,
          foundationDate: data.foundationDate,
          addressStreet: data.addressStreet,
          addressState: data.addressState,
          addressCity: data.addressCity,
          addressNeighborhood: data.addressNeighborhood,
          addressNumber: data.addressNumber,
          addressPostalCode: data.addressPostalCode,
          addressComplement: data.addressComplement,
          repName: data.repName,
          repIndividualDocumentNumber: data.repIndividualDocumentNumber,
          repDocNumber: data.repDocNumber,
          repDocNumberType: data.repDocNumberType,
          repBirthDate: data.repBirthDate,
          repPhone: data.repPhone,
          repEmail: data.repEmail,
          repIsPep: data.repIsPep,
          repMotherName: data.repMotherName,
          repMaritalStatus: data.repMaritalStatus,
          repNationality: data.repNationality,
          repAddressStreet: data.repAddressStreet,
          repAddressState: data.repAddressState,
          repAddressCity: data.repAddressCity,
          repAddressNeighborhood: data.repAddressNeighborhood,
          repAddressNumber: data.repAddressNumber,
          repAddressPostalCode: data.repAddressPostalCode,
          repAddressComplement: data.repAddressComplement,
        },
      });

      yield put(
        notifierActions.enqueue({
          message: `Fornecedor adicionado! Convite enviado para ${data.email}`,
          options: {
            variant: 'success',
          },
        })
      );
      yield put(adminActions.setIsLoading(false));
      yield put(companySelectActions.loadCompanies());
      yield navigate('/fornecedores');
    } catch (e) {
      let message: string | undefined;
      const eAny = e as unknown as any;
      if (eAny.response && eAny.response.json) {
        const responseData: { message: string } = yield eAny.response.json();
        message = responseData.message;
      }
      yield put(appActions.setError(message ?? (e as Error).message));
      yield put(adminActions.setIsLoading(false));
    }
  }
}

function* loadSelectedSupplier(idSupplier: string) {
  const { selectedSupplier }: AdminState = yield select(
    (state) => state.supplier
  );
  if (!selectedSupplier || selectedSupplier.id !== idSupplier) {
    try {
      yield put(adminActions.setIsLoadingSelectedSupplier(true));
      const company: Models.Company = yield WebCompanyHelpers.getById(
        idSupplier
      );
      yield put(adminActions.setSelectedSupplier(company));
      if (company.idOwner) {
        const user: Models.User = yield WebUserHelpers.getById(company.idOwner);
        yield put(adminActions.setSelectedSupplierOwner(user));
      }
      yield put(adminActions.setIsLoadingSelectedSupplier(false));
    } catch (error) {
      yield put(adminActions.setIsLoadingSelectedSupplier(false));
      throw error;
    }
  }
}

function* loadSelectedSupplierBankAccount(idSupplier: string) {
  const { selectedSupplier }: AdminState = yield select(
    (state) => state.supplier
  );
  try {
    if (!selectedSupplier || selectedSupplier.id !== idSupplier) {
      const bankAccount: Models.BankAccount =
        yield WebBankAccountHelpers.getByRetailerId(idSupplier);
      yield put(adminActions.setSelectedSupplierBankAccount(bankAccount));
    }
  } catch (error) {
    yield put(appActions.setError((error as Error)?.message ?? 'Error'));
    yield put(adminActions.setIsLoading(false));
  }
}

function* loadSupplierBankAccountTransactions(
  payload: Models.ListSupplierBankAccountTransactionsRequest
) {
  const rpcCallResult: RPC.Call<
    void,
    Models.ListBankAccountTransactionResponse
  > = yield WebBankAccountHelpers.listSupplierTransactions(payload);
  const { result, resultPayload, error } = rpcCallResult;
  console.log('rpcCallResult: ', rpcCallResult);

  if (result === 'success') {
    if (!resultPayload) throw new Error('Unexpected empty payload');
    yield put(adminActions.setSupplierBankAccountTransactions(resultPayload));
    yield put(adminActions.setIsLoading(false));
  } else {
    yield put(appActions.setError(error ?? 'Error'));
    yield put(adminActions.setIsLoading(false));
  }
}

function* loadSupplierTransactionConciliations(
  payload: Models.ListSupplierBankAccountTransactionsRequest
) {
  const transactions: Models.FinancialTransaction[] =
    yield WebBankAccountHelpers.listSupplierTransactionConciliations(payload);

  yield put(adminActions.setSupplierTransactionConciliations(transactions));
}

function* watchLoadSupplierBankAccountTransactions() {
  while (true) {
    const {
      payload,
    }: PayloadAction<Models.ListSupplierBankAccountTransactionsRequest> =
      yield take(adminActions.loadSupplierBankAccountTransactions);
    yield put(adminActions.setIsLoading(true));
    yield fork(loadSelectedSupplier, payload.idSupplier);
    yield fork(loadSelectedSupplierBankAccount, payload.idSupplier);
    yield fork(loadSupplierBankAccountTransactions, payload);
    yield fork(loadSupplierTransactionConciliations, payload);
  }
}

export default [
  watchLoadSuppliers,
  watchAddSupplier,
  watchLoadSupplierBankAccountTransactions,
];
