'use strict';
import { useAuth0 } from '@auth0/auth0-react';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import {
  Avatar,
  Badge,
  Box,
  Button,
  Chip,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import React, { useState } from 'react';
import { getFirestoreConsoleLink } from '../../helpers/firebase';
import { format } from '../../helpers/format';
import { useAppDispatch, useAppSelector } from '../../helpers/hooks';
import { drawerWidth } from '../../helpers/themes';
import useCompanyStatus from '../../hooks/useCompanyStatus';
import useUserRole from '../../hooks/useUserRole';
import FirebaseIcon from '../../icons/FirebaseIcon';
import CompanySelect from '../company-select/CompanySelect';
import { AppDrawer } from './AppDrawer';
import { IdleDetector } from './IdleDetector';
import { ProfileMenu } from './ProfileMenu';
import { appActions } from './app-slice';

export const MainAppBarAndDrawer = () => {
  // const [remaining, setRemaining] = useState<number>(0);
  // const [isIdlePromptOpen, setIsIdlePromptOpen] = useState(false);

  // const onIdle = () => {
  //   setIsIdlePromptOpen(false);
  // };

  // const onActive = () => {
  //   setIsIdlePromptOpen(false);
  // };

  // const onPresenceChange = async (presence: PresenceType) => {
  //   if (presence.type === 'idle')
  //     await logout({
  //       logoutParams: {
  //         returnTo: `${process.env.REACT_APP_AUTH0_LOGOUT_URL}?reason=expired`,
  //       },
  //     });
  // };

  // const onPrompt = () => {
  //   setIsIdlePromptOpen(true);
  // };

  // const { activate, getRemainingTime } = useIdleTimer({
  //   onIdle,
  //   onActive,
  //   onPresenceChange,
  //   onPrompt,
  //   timeout: 600_000,
  //   throttle: 500,
  //   promptBeforeIdle: 45_000,
  // });

  // const handleStillHere = () => {
  //   activate();
  // };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setRemaining(Math.ceil(getRemainingTime() / 1000));
  //   }, 500);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // });

  const { isLoading, loginWithRedirect, isAuthenticated, user, logout } =
    useAuth0();
  const dispatch = useAppDispatch();
  const [avatarAnchorEl, setAvatarAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const { isInitializing, company, theme, isDrawerOpen } = useAppSelector(
    (state) => state.app
  );
  const { isDebugActivated } = useAppSelector((state) => state.devTools);
  const {
    documentsOk,
    profileOk,
    bankAccountOk,
    targetBankAccountOk,
    supplierSettingsOk,
  } = useCompanyStatus();

  const avatarOk =
    documentsOk &&
    supplierSettingsOk &&
    profileOk &&
    bankAccountOk &&
    targetBankAccountOk;

  const { isAdmin } = useUserRole();
  const muiTheme = useTheme();

  const onAvatarClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAvatarAnchorEl(event.currentTarget);
  };

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: '/',
      },
    });
  };
  const handleThemeChange = (theme: 'light' | 'dark') => {
    dispatch(appActions.setTheme(theme));
  };

  const env =
    process.env.REACT_APP_ENV === 'paguerecebivel-dev'
      ? 'Ambiente de Desenvolvimento'
      : process.env.REACT_APP_ENV === 'paguerecebivel-stg'
      ? 'Ambiente de Demonstração'
      : 'prod';

  return (
    <>
      <AppBar
        position="absolute"
        sx={{
          // zIndex: muiTheme.zIndex.drawer + 1,
          // transition: '400ms',
          // ...(isDrawerOpen && {
          //   zIndex: muiTheme.zIndex.drawer - 1,
          // }),
          boxShadow: 'none',
          borderBottom: '0px',
          borderTop: '0px',
          borderLeft: '0px',
          borderRight: '0px',
          borderColor: muiTheme.palette.divider,
          backgroundColor: muiTheme.palette.background.default,
          color: muiTheme.palette.getContrastText(
            muiTheme.palette.background.default
          ),
          paddingLeft: drawerWidth,
          transition: muiTheme.transitions.create('all', {
            easing: muiTheme.transitions.easing.sharp,
            duration: muiTheme.transitions.duration.enteringScreen,
          }),
          ...(!isDrawerOpen && {
            transition: muiTheme.transitions.create('all', {
              easing: muiTheme.transitions.easing.sharp,
              duration: muiTheme.transitions.duration.leavingScreen,
            }),
            paddingLeft: muiTheme.spacing(5),
            [muiTheme.breakpoints.up('sm')]: {
              paddingLeft: muiTheme.spacing(7.75),
            },
          }),
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          width={1}
          paddingX={2}
        >
          {isAdmin ? (
            <Box>
              <CompanySelect />
            </Box>
          ) : (
            <Box>
              <Typography variant="h5">
                {company?.fantasy ?? company?.name}
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                {format(company?.docNumber, 'docNumber')}
              </Typography>
            </Box>
          )}
          <Box flexGrow={1} />
          {env !== 'prod' && (
            <Box paddingX={2}>
              <Chip
                label={env}
                size="small"
                color="secondary"
                variant="outlined"
              />
            </Box>
          )}
          {isDebugActivated && (
            <IconButton
              target="_blank"
              rel="noopener noreferrer"
              href={getFirestoreConsoleLink(`/companies/${company?.id}`)}
            >
              <FirebaseIcon />
            </IconButton>
          )}
          <Tooltip title={theme === 'dark' ? 'Acender a luz' : 'Apagar a luz'}>
            <IconButton
              color="inherit"
              onClick={() =>
                handleThemeChange(theme === 'dark' ? 'light' : 'dark')
              }
              size="large"
            >
              {theme === 'dark' ? <LightModeIcon /> : <DarkModeIcon />}
            </IconButton>
          </Tooltip>
          {isAuthenticated ? (
            <>
              {user && (
                <IconButton
                  aria-label="profile"
                  color="inherit"
                  onClick={onAvatarClick}
                  size="large"
                >
                  <Badge
                    invisible={avatarOk}
                    overlap="circular"
                    badgeContent="!"
                    color="error"
                  >
                    <Avatar
                      color="inherit"
                      src={user.picture}
                      alt={user.name}
                    />
                  </Badge>
                </IconButton>
              )}
            </>
          ) : (
            <Button variant="contained" onClick={handleLogin}>
              Log In
            </Button>
          )}
        </Stack>
      </AppBar>
      {!isLoading && !isInitializing && (
        <AppDrawer isDrawerOpen={isDrawerOpen} />
      )}
      <ProfileMenu
        anchorEl={avatarAnchorEl}
        onMenuClose={() => setAvatarAnchorEl(null)}
      />
      <IdleDetector
        onExpired={() => {
          logout({
            logoutParams: {
              returnTo: `${process.env.REACT_APP_AUTH0_LOGOUT_URL}?reason=expired`,
            },
          });
        }}
      />
    </>
  );
};
