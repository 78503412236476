import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import RefreshIcon from '@mui/icons-material/Refresh';
import SendIcon from '@mui/icons-material/Send';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { Alert, LoadingButton } from '@mui/lab';
import {
  Box,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import React, { useEffect, useState } from 'react';
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog';
import EditForm from '../../components/EditForm/EditForm';
import EditFormContent from '../../components/EditForm/EditFormContent';
import EditFormHeader from '../../components/EditForm/EditFormHeader';
import { getFirestoreConsoleLink } from '../../helpers/firebase';
import { format } from '../../helpers/format';
import { useAppDispatch, useAppSelector } from '../../helpers/hooks';
import { safeParseFloat } from '../../helpers/safe-parse';
import { validateFloat } from '../../helpers/validations';
import useUserRole from '../../hooks/useUserRole';
import FirebaseIcon from '../../icons/FirebaseIcon';
import CompanyInfo from '../supplier/components/CompanyInfo';
import { supplierActions } from '../supplier/supplier.slice';
import { bankAccountActions } from './bank-account-slice';

type DetailProps = {
  label: string;
  value?: string;
  children?: React.ReactNode;
};
const Detail = ({ label, value, children }: DetailProps) => (
  <Box>
    <Typography variant="body2" color="textSecondary" component="p">
      {label}:
    </Typography>
    {value && (
      <Typography variant="body1" color="textPrimary" component="p">
        {' '}
        {value ?? '-'}
      </Typography>
    )}
    {children}
  </Box>
);

export default function RetailerBankAccount() {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.app);
  const {
    selectedRetailer,
    selectedRetailerBankAccount,
    isLoadingSelectedRetailerBankAccount,
  } = useAppSelector((state) => state.supplier);
  const { isDebugActivated } = useAppSelector((state) => state.devTools);
  const {
    bankAccountInfo,
    isTransferring,
    isInternalTransferDialogOpen,
    isInternalTransferConfirmationOpen,
    isLoadingBankAccountInfo,
  } = useAppSelector((state) => state.bankAccount);

  const { isAdmin } = useUserRole();

  const [isBalanceVisible, setIsBalanceVisible] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [transferAmount, setTransferAmount] = useState('');
  const [transferDescription, setTransferDescription] = useState('');

  const isBankAccountReady =
    selectedRetailerBankAccount?.bankStatus === 'account_opened';

  useEffect(() => {
    if (selectedRetailer && selectedRetailerBankAccount && isBankAccountReady) {
      dispatch(bankAccountActions.getRetailerAccountInfo(selectedRetailer.id));
    }
  }, [JSON.stringify(selectedRetailerBankAccount)]);

  useEffect(() => {
    if (selectedRetailer)
      dispatch(supplierActions.loadRetailerBankAccount(selectedRetailer.id));
  }, [selectedRetailer]);

  const handleCloseClick = () => {
    dispatch(supplierActions.setIsRetailerBankAccountPanelOpen(false));
  };
  const handleCreateClick = () => {
    setIsConfirmationOpen(true);
  };
  const handleConfirmClick = () => {
    if (selectedRetailer) {
      dispatch(
        bankAccountActions.createAccount({ idRetailer: selectedRetailer.id })
      );
      setIsConfirmationOpen(false);
    }
  };

  const handleInternalTransferConfirmClick = () => {
    if (selectedRetailer)
      dispatch(
        bankAccountActions.internalTransferToTargetAccount({
          idRetailer: selectedRetailer.id,
          transferAmount: safeParseFloat(transferAmount),
          description: transferDescription,
        })
      );
  };

  const bankAccountNameWithCode = selectedRetailerBankAccount
    ? selectedRetailerBankAccount.bankCode
      ? `${selectedRetailerBankAccount.bankCode} - ${selectedRetailerBankAccount.bankName}`
      : selectedRetailerBankAccount.bankName
    : '';

  const accountData = `Agência ${selectedRetailerBankAccount?.branch} - Número ${selectedRetailerBankAccount?.account}-${selectedRetailerBankAccount?.accountDigit}`;

  const balanceAfterTransfer =
    (bankAccountInfo?.balance ?? 0) - safeParseFloat(transferAmount);

  const isBalanceValid =
    validateFloat(transferAmount) &&
    balanceAfterTransfer >= 0 &&
    safeParseFloat(transferAmount) > 0;

  const isTransferDescriptionValid = transferDescription.trim() !== '';

  return (
    <EditForm>
      <EditFormHeader
        title="Conta de Liquidação"
        onCloseClick={handleCloseClick}
        actions={
          isDebugActivated && (
            <IconButton
              target="_blank"
              rel="noopener noreferrer"
              href={getFirestoreConsoleLink(
                `/bank-accounts/${selectedRetailerBankAccount?.id}`
              )}
            >
              <FirebaseIcon />
            </IconButton>
          )
        }
      />
      <EditFormContent>
        <Stack direction="column" gap={1}>
          <CompanyInfo />
          {isLoadingSelectedRetailerBankAccount ? (
            <Stack width={1} alignItems="center" justifyContent="center" p={4}>
              <CircularProgress />
            </Stack>
          ) : (
            <>
              {selectedRetailerBankAccount && isBankAccountReady ? (
                <>
                  <Stack direction="row" alignItems="center">
                    <Box flexGrow={1}>
                      <Typography>Dados da Conta de Liquidação</Typography>
                    </Box>

                    <IconButton
                      onClick={() => setIsBalanceVisible(!isBalanceVisible)}
                    >
                      {isBalanceVisible ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        if (selectedRetailer)
                          dispatch(
                            bankAccountActions.getRetailerAccountInfo(
                              selectedRetailer.id
                            )
                          );
                      }}
                    >
                      <RefreshIcon />
                    </IconButton>
                  </Stack>
                  <Stack direction="row" gap={2}>
                    <Detail
                      label="Saldo"
                      value={
                        isLoadingBankAccountInfo
                          ? ''
                          : isBalanceVisible
                          ? format(bankAccountInfo?.balance, 'money')
                          : '***'
                      }
                    >
                      {isLoadingBankAccountInfo && (
                        <CircularProgress size={15} />
                      )}
                    </Detail>
                    {!isLoadingBankAccountInfo && isBalanceVisible && (
                      <IconButton
                        onClick={() =>
                          dispatch(
                            bankAccountActions.setIsInternalTransferDialogOpen(
                              true
                            )
                          )
                        }
                      >
                        <SendIcon />
                      </IconButton>
                    )}
                  </Stack>
                  <Detail
                    label="Saldo Bloqueado"
                    value={
                      isLoadingBankAccountInfo
                        ? ''
                        : isBalanceVisible
                        ? format(bankAccountInfo?.blocked_balance, 'money')
                        : '***'
                    }
                  >
                    {isLoadingBankAccountInfo && <CircularProgress size={15} />}
                  </Detail>

                  <Detail label="Banco" value={bankAccountNameWithCode} />
                  <Detail
                    label="ISPB"
                    value={format(selectedRetailerBankAccount.ispb, 'ispb')}
                  />
                  <Detail label="Dados" value={accountData} />
                  <Detail
                    label="Documento"
                    value={format(
                      selectedRetailerBankAccount.documentNumber,
                      'docNumber'
                    )}
                  />
                  <Detail
                    label="Nome"
                    value={selectedRetailerBankAccount.ownerName}
                  />
                  {isDebugActivated && (
                    <>
                      <Detail
                        label="ID"
                        value={selectedRetailerBankAccount.bankId}
                      />
                      <Detail
                        label="Status"
                        value={selectedRetailerBankAccount.bankStatus}
                      />
                    </>
                  )}
                  <Detail
                    label="Data de criação"
                    value={format(
                      selectedRetailerBankAccount.dateadded,
                      'dateTime'
                    )}
                  />
                </>
              ) : (
                <>
                  {isAdmin && !selectedRetailerBankAccount && (
                    <LoadingButton
                      loading={isLoading}
                      loadingPosition="start"
                      startIcon={<AddIcon />}
                      variant="outlined"
                      onClick={handleCreateClick}
                    >
                      Criar nova conta
                    </LoadingButton>
                  )}
                  {selectedRetailerBankAccount && !isBankAccountReady && (
                    <>
                      <Stack direction="row" gap={1} alignItems="center">
                        <Box flexGrow={1}>
                          <Alert variant="outlined" severity="info">
                            Conta em análise
                          </Alert>
                        </Box>
                        <IconButton
                          disabled={isLoadingBankAccountInfo}
                          onClick={() => {
                            if (selectedRetailer)
                              dispatch(
                                bankAccountActions.getRetailerAccountInfo(
                                  selectedRetailer.id
                                )
                              );
                          }}
                        >
                          {isLoadingBankAccountInfo ? (
                            <CircularProgress size="1em" />
                          ) : (
                            <RefreshIcon />
                          )}
                        </IconButton>
                      </Stack>
                      <Detail label="Banco" value={bankAccountNameWithCode} />
                      <Detail
                        label="ISPB"
                        value={format(selectedRetailerBankAccount.ispb, 'ispb')}
                      />
                      <Detail label="Dados" value={accountData} />
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Stack>
      </EditFormContent>
      <ConfirmationDialog
        open={isConfirmationOpen}
        title="Confirmação"
        actions={[
          {
            name: 'Não',
            buttonProps: {
              autoFocus: true,
              onClick: () => {
                setIsConfirmationOpen(false);
              },
            },
          },
          {
            name: 'Sim',
            showLoading: true,
            buttonProps: {
              variant: 'contained',
              color: 'primary',
              startIcon: <CheckIcon />,
              onClick: handleConfirmClick,
            },
          },
        ]}
      >
        Deseja criar uma nova conta?
      </ConfirmationDialog>
      <ConfirmationDialog
        open={isInternalTransferDialogOpen}
        title="Transferência Interna"
        isLoading={isTransferring}
        actions={[
          {
            name: 'Cancelar',
            buttonProps: {
              autoFocus: true,
              onClick: () => {
                dispatch(
                  bankAccountActions.setIsInternalTransferDialogOpen(false)
                );
              },
            },
          },
          {
            name: 'Transferir',
            showLoading: true,
            buttonProps: {
              disabled: !isBalanceValid,
              variant: 'contained',
              color: 'primary',
              startIcon: <SendIcon />,
              onClick: () =>
                dispatch(
                  bankAccountActions.setIsInternalTransferConfirmationOpen(true)
                ),
            },
          },
        ]}
      >
        <Stack direction="column" gap={2}>
          <TextField
            id="transferAmount"
            label="Valor"
            type="text"
            value={transferAmount}
            onChange={(e) => setTransferAmount(e.target.value)}
            error={!isBalanceValid}
            helperText={!isBalanceValid ? 'Insira um valor válido' : ''}
          />
          <TextField
            id="transferDescription"
            label="Descrição"
            type="text"
            value={transferDescription}
            onChange={(e) => setTransferDescription(e.target.value)}
            error={!isTransferDescriptionValid}
            helperText={
              !isTransferDescriptionValid ? 'A descrição é obrigatória' : ''
            }
          />
          <Box
            display="flex"
            flexDirection="row"
            alignItems="flex-start"
            gap={1}
          >
            <Typography component="span">
              Saldo após transferência: {format(balanceAfterTransfer, 'money')}
            </Typography>
          </Box>
        </Stack>
      </ConfirmationDialog>
      <ConfirmationDialog
        open={isInternalTransferConfirmationOpen}
        title="Confirmação"
        isLoading={isTransferring}
        actions={[
          {
            name: 'Cancelar',
            buttonProps: {
              autoFocus: true,
              onClick: () => {
                dispatch(
                  bankAccountActions.setIsInternalTransferConfirmationOpen(
                    false
                  )
                );
              },
            },
          },
          {
            name: 'Confirmar',
            showLoading: true,
            buttonProps: {
              variant: 'contained',
              color: 'primary',
              startIcon: <CheckIcon />,
              onClick: handleInternalTransferConfirmClick,
            },
          },
        ]}
      >
        <Stack direction="column" gap={2}>
          <Typography>Verifique os dados antes de confirmar:</Typography>
          <Detail label="Valor" value={format(transferAmount, 'money')} />
          <Detail
            label="Saldo após transferência"
            value={format(balanceAfterTransfer, 'money')}
          />
        </Stack>
      </ConfirmationDialog>
    </EditForm>
  );
}
