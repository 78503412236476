import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Models, QITech } from 'types';
import { SearchPixKeyResponse } from '../admin/admin.types';

export type BankAccountState = {
  isLoadingBankAccountInfo: boolean;
  bankAccountInfo?: QITech.GetAccountInfoResponseWithBankStatus;
  searchPixKeyResponse: SearchPixKeyResponse | undefined;
  isSearchingPixKey: boolean;
  isSavingTargetAccount: boolean;
  isTargetAccountInEditMode: boolean;
  isTransferring: boolean;
  isTransferDialogOpen: boolean;
  isTransferConfirmationOpen: boolean;
  isInternalTransferDialogOpen: boolean;
  isInternalTransferConfirmationOpen: boolean;
  isPixLimitsFormOpen: boolean;
  isLoadingPixLimitsUsage: boolean;
  isRequestingNewPixLimits: boolean;
  pixLimitsUsage?: QITech.GetPixLimitUsageResponse;
};
const initialState: BankAccountState = {
  isLoadingBankAccountInfo: false,
  isSearchingPixKey: false,
  isSavingTargetAccount: false,
  isTargetAccountInEditMode: false,
  searchPixKeyResponse: undefined,
  isTransferring: false,
  isTransferDialogOpen: false,
  isTransferConfirmationOpen: false,
  isInternalTransferDialogOpen: false,
  isInternalTransferConfirmationOpen: false,
  isLoadingPixLimitsUsage: false,
  isPixLimitsFormOpen: false,
  isRequestingNewPixLimits: false,
};

export const bankAccountSlice = createSlice({
  name: 'bank-account',
  initialState,
  reducers: {
    createAccount: (
      _state,
      _action: PayloadAction<Models.WebCreateBankAccountRequest>
    ) => {},
    getSupplierAccountInfo: () => {},
    getRetailerAccountInfo: (_state, _action: PayloadAction<string>) => {},
    setAccountInfo: (
      state,
      { payload }: PayloadAction<QITech.GetAccountInfoResponseWithBankStatus>
    ) => {
      state.bankAccountInfo = payload;
    },
    setIsLoadingBankAccountInfo: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isLoadingBankAccountInfo = payload;
    },
    searchPixKey: (_state, _action: PayloadAction<string>) => {},
    setIsSearchingPixKey: (state, { payload }: PayloadAction<boolean>) => {
      state.isSearchingPixKey = payload;
    },
    setSearchPixKeyResponse: (
      state,
      { payload }: PayloadAction<SearchPixKeyResponse | undefined>
    ) => {
      state.searchPixKeyResponse = payload;
    },
    createTargetBankAccount: (
      _state,
      _action: PayloadAction<Models.SaveTargetBankAccountPayload>
    ) => {},
    updateTargetBankAccount: (
      _state,
      _action: PayloadAction<Models.UpdateTargetBankAccountPayload>
    ) => {},
    setIsSavingTargetAccount: (state, { payload }: PayloadAction<boolean>) => {
      state.isSavingTargetAccount = payload;
    },
    setIsTargetAccountInEditMode: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isTargetAccountInEditMode = payload;
    },
    setIsTransferring: (state, { payload }: PayloadAction<boolean>) => {
      state.isTransferring = payload;
    },
    transferToTargetAccount: (
      _state,
      _action: PayloadAction<Models.TransferToTargetAccountRequest>
    ) => {},
    internalTransferToTargetAccount: (
      _state,
      _action: PayloadAction<Models.InternalTransferToTargetAccountRequest>
    ) => {},
    setIsTransferDialogOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isTransferDialogOpen = payload;
    },
    setIsTransferConfirmationOpen: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isTransferConfirmationOpen = payload;
    },
    setIsInternalTransferDialogOpen: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isInternalTransferDialogOpen = payload;
    },
    setIsInternalTransferConfirmationOpen: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isInternalTransferConfirmationOpen = payload;
    },
    setIsPixLimitsFormOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isPixLimitsFormOpen = payload;
    },
    setPixLimitsUsage: (
      state,
      { payload }: PayloadAction<QITech.GetPixLimitUsageResponse>
    ) => {
      state.pixLimitsUsage = payload;
    },
    setIsLoadingPixLimitsUsage: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isLoadingPixLimitsUsage = payload;
    },
    setIsRequestingNewPixLimits: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isRequestingNewPixLimits = payload;
    },
    requestNewPixLimits: (
      _state,
      _action: PayloadAction<Models.NewPixLimitsRequest>
    ) => {},
  },
});

export const bankAccountActions = bankAccountSlice.actions;

export default bankAccountSlice.reducer;
