import {
  Alert,
  Box,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { Models } from 'types';
import { WarrantyExecutionStatus } from '../../../components/WarrantyExecutionStatus/WarrantyExecutionStatus';
import { format } from '../../../helpers/format';
import { useAppSelector } from '../../../helpers/hooks';
import {
  getWarrantyExecutionDate,
  getWarrantyExecutionStatus,
} from '../../../helpers/payment-request-status';
import { getFilterUrl } from '../FornecedorListaPagamentos/get-filter-url';
import { ListaPagamentoFornecedor } from '../supplier.types';
import { DashboardCard } from './DashboardCard';

type ActiveCardProps = {
  paymentParams: Models.LoadPaymentRequestsForSupplierRequest;
};
export const ActiveCard = ({ paymentParams }: ActiveCardProps) => {
  let navigate = useNavigate();

  const { isLoadingPaymentRequests, paymentRequests, retailers } =
    useAppSelector((state) => state.supplier);

  const activeStatus: Models.PaymentRequestStatus[] = ['ativo'];

  const activePaymentRequests = paymentRequests.filter(
    (p) =>
      activeStatus.includes(p.status) &&
      getWarrantyExecutionStatus(p) !== 'overdue'
  );
  const sumActivePayments = activePaymentRequests
    .map(({ totalValue }) => totalValue)
    .reduce((acc, cur) => {
      acc += cur;
      return acc;
    }, 0);

  const enhancedData: ListaPagamentoFornecedor[] = activePaymentRequests.map(
    (d) => {
      const retailer = retailers.find((r) => r.id === d.idRetailer);
      return {
        idRetailer: d.idRetailer,
        id: d.id,
        externalId: d.externalId,
        cliente: retailer ? retailer.fantasy ?? retailer.name : '...',
        numero: d.id,
        dataPedido: dayjs(d.dateadded).format('YYYYMMDD'),
        dataVencimento: d.dueDate,
        dataInicio: d.firstIncomingDate,
        operacao:
          d.effectType === 'garantia' ? 'Garantia' : 'Troca de Titularidade',
        valorTotal: d.totalValue,
        status: d.status,
        detalhes: 'pagamento-1',
        effectType: d.effectType,
        prioridade: d.effectPriority,
        statusExecucaoGarantia: getWarrantyExecutionStatus(d),
        dataExecucao: getWarrantyExecutionDate(d),
      };
    }
  );

  const executionAlert = !!enhancedData.find(
    ({ statusExecucaoGarantia }) => statusExecucaoGarantia === 'executing'
  );
  const overdueAlert = !!enhancedData.find(
    ({ statusExecucaoGarantia }) => statusExecucaoGarantia === 'overdue'
  );

  return (
    <DashboardCard
      onClick={() => navigate(getFilterUrl('/pagamentos', paymentParams))}
      title="Pagamentos em Andamento"
      value={
        isLoadingPaymentRequests
          ? '...'
          : sumActivePayments > 0
          ? format(sumActivePayments, 'money', { decimals: 0 })
          : '-'
      }
      color={sumActivePayments > 0 ? 'success.main' : 'text.secondary'}
    >
      {isLoadingPaymentRequests ? (
        <Box
          p={2}
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexGrow={1}
          height={1}
        >
          <CircularProgress color="error" />
        </Box>
      ) : (
        <Stack direction="column" p={2} gap={1}>
          {overdueAlert && (
            <Alert severity="error" variant="filled">
              Existem pagamentos vencidos. Se os pagamentos foram feitos, faça o
              cancelamento da garantia para evitar execução indevida!
            </Alert>
          )}
          {executionAlert && (
            <Alert severity="warning" variant="outlined">
              Existem pagamentos em execução. Não é mais possível receber o
              pagamento do pedido!
            </Alert>
          )}

          {enhancedData.length === 0 ? (
            <Alert severity="info" variant="outlined">
              Não existe nenhum pagamento em andamento.
            </Alert>
          ) : (
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Cliente</TableCell>
                    <TableCell align="center">ID do pedido</TableCell>
                    <TableCell align="center">Vencimento</TableCell>
                    <TableCell align="center">Execução</TableCell>
                    <TableCell align="center">Valor</TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {enhancedData.map((row) => {
                    return (
                      <TableRow
                        hover
                        key={row.id}
                        sx={{
                          cursor: 'pointer',
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                        onClick={() =>
                          navigate(`/pagamentos/${row.idRetailer}/${row.id}`)
                        }
                      >
                        <TableCell component="th" scope="row">
                          {row.cliente}
                        </TableCell>
                        <TableCell align="center">{row.externalId}</TableCell>
                        <TableCell align="center">
                          {format(row.dataVencimento, 'date')}
                        </TableCell>
                        <TableCell align="center">
                          {format(row.dataExecucao, 'date')}
                        </TableCell>
                        <TableCell align="center">
                          {format(row.valorTotal, 'money')}
                        </TableCell>
                        <TableCell align="center">
                          {row.statusExecucaoGarantia && (
                            <WarrantyExecutionStatus
                              status={row.statusExecucaoGarantia}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Stack>
      )}
    </DashboardCard>
  );
};
