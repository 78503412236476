import { Button, Chip, Link, Stack, useTheme } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { gridClasses } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { Constants } from 'pr-constants';
import { MouseEvent, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Models } from 'types';
import { ConciliationStatus } from '../../../components/ConciliationStatus/ConciliationStatus';
import FilterForm from '../../../components/FilterForm/FilterForm';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import ResponsiveDialog from '../../../components/ResponsiveDialog/ResponsiveDialog';
import DataTable from '../../../components/Table/DataTable';
import { TableColumn } from '../../../components/Table/table.types';
import { getFirestoreConsoleLink } from '../../../helpers/firebase';
import { format } from '../../../helpers/format';
import { useAppDispatch, useAppSelector } from '../../../helpers/hooks';
import useUserRole from '../../../hooks/useUserRole';
import { ExtratoFilterForm } from '../../../types';
import BankAccountInfo from '../components/BankAccountInfo';
import CompanyInfo from '../components/CompanyInfo';
import { supplierActions } from '../supplier.slice';
import { ExtratoVarejista } from '../supplier.types';
import ConciliacaoVarejista from './ConciliacaoVarejista';

function FornecedorExtratoVarejista() {
  const navigate = useNavigate();
  const {
    retailerBankAccountTransactions,
    retailerTransactionConciliations,
    isRetailerConciliationPanelOpen,
    selectedRetailerBankAccount,
    isLoadingRetailerBankAccountTransactions,
  } = useAppSelector((state) => state.supplier);
  const { isDebugActivated } = useAppSelector((state) => state.devTools);
  const dispatch = useAppDispatch();
  const { isAdmin } = useUserRole();

  const { idRetailer } = useParams();
  const [params] = useSearchParams();
  const startDate = params.get('s');
  const endDate = params.get('e');
  const defaultStartDate = dayjs().startOf('month');
  const defaultEndDate = dayjs();
  const muiTheme = useTheme();
  const dateExpression = params.get('x') ?? undefined;
  const queryParams: ExtratoFilterForm = {
    startDate: startDate ?? defaultStartDate.format('YYYYMMDD'),
    endDate: endDate ?? defaultEndDate.format('YYYYMMDD'),
  };

  const currentDateRangeLabel =
    queryParams.startDate !== queryParams.endDate
      ? `${dayjs(queryParams.startDate).format('DD/MM/YYYY')} a ${dayjs(
          queryParams.endDate
        ).format('DD/MM/YYYY')}`
      : dayjs(queryParams.startDate).format('DD/MM/YYYY');
  const currentDateRangeFilter = `Data: ${currentDateRangeLabel}`;

  const navigateWithFilter = (
    destination: string,
    { dateExpression, startDate, endDate }: ExtratoFilterForm
  ) => {
    const dateFilter = `?s=${startDate}&e=${endDate}`;
    const dateExpressionFilter = dateExpression ? `&x=${dateExpression}` : '';
    navigate(`${destination}${dateFilter}${dateExpressionFilter}`);
  };

  const handleFilter = (data: ExtratoFilterForm) => {
    navigateWithFilter('.', {
      dateExpression:
        data.dateExpression as Models.LoadPaymentRequestsForSupplierRequestDateExpression,
      startDate: dayjs(data.startDate).format('YYYYMMDD'),
      endDate: dayjs(data.endDate).format('YYYYMMDD'),
    });
  };

  const handleResetFilter = () => {
    navigateWithFilter('.', {
      startDate: queryParams.startDate,
      endDate: queryParams.endDate,
      dateExpression,
    });
  };

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleFilterChipClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    console.log('use Effect: idRetailer = ', idRetailer);
    console.log(
      'use Effect: selectedRetailerBankAccount = ',
      selectedRetailerBankAccount
    );

    if (idRetailer) {
      dispatch(
        supplierActions.loadRetailerBankAccountTransactions({
          startDate: queryParams.startDate,
          endDate: queryParams.endDate,
          idRetailer,
        })
      );
    }
  }, [idRetailer, JSON.stringify(selectedRetailerBankAccount)]);

  const columns: TableColumn<ExtratoVarejista>[] = [
    { id: 'id', label: 'id', hidden: !isDebugActivated },
    {
      id: 'internalId',
      label: 'internalId',
      hidden: !isDebugActivated,
      renderer: (v) => (
        <Link
          target="_blank"
          rel="noopener noreferrer"
          variant="caption"
          color="textSecondary"
          href={getFirestoreConsoleLink(`/financial-transactions/${v}`)}
        >
          {v}
        </Link>
      ),
    },
    { id: 'data', label: 'Data', type: 'date', flex: 0.7 },
    { id: 'tipo', label: 'Tipo', center: false },
    { id: 'detalhe', label: 'Detalhe', center: false },
    { id: 'idPedidos', label: 'Pedidos', formatter: (v) => v.join(', ') },

    {
      id: 'statusConciliacao',
      label: 'Status',
      renderer: (
        status: Models.FinancialTransactionConciliationStatus | undefined
      ) => <ConciliationStatus status={status} />,
      flex: 0.3,
      center: true,
      cellClassName: 'icon',
    },
    {
      id: 'valor',
      label: 'Valor',
      type: 'money',
      flex: 0.7,
      renderer: (v) => (
        <span
          style={{
            color:
              v < 0 ? muiTheme.palette.error.main : muiTheme.palette.info.main,
          }}
        >
          {format(v, 'money')}
        </span>
      ),
    },
    { id: 'saldo', label: 'Saldo', type: 'money', flex: 0.7 },
  ];

  const enhancedData: ExtratoVarejista[] = retailerBankAccountTransactions
    ? retailerBankAccountTransactions.data.map((d) => {
        const conciliation = (retailerTransactionConciliations ?? []).find(
          ({ transactionId }) => transactionId === d.id
        );

        const [conciliationDetail] = conciliation?.conciliationDetail ?? [];
        const idPedidos = conciliation?.conciliationDetail
          ? conciliation.conciliationDetail.map(({ externalId }) => externalId)
          : [];

        let detalhe: string;

        if (conciliation && conciliationDetail) {
          const settlementConciliationDetail =
            conciliationDetail as Models.FinancialTransactionConciliationSettlementDetail;
          if (settlementConciliationDetail.debtor) {
            detalhe = `${Constants.getDebtor(
              settlementConciliationDetail.debtor
            )} - ${Constants.getPaymentScheme(
              settlementConciliationDetail.paymentScheme
            )} - ${format(
              settlementConciliationDetail.receivableIncomingDate,
              'date'
            )} - pedidos ${idPedidos}`;
          } else {
            detalhe = conciliation.description;
          }
        } else detalhe = d.description;

        return {
          id: d.id,
          internalId: conciliation?.id,
          data: d.createdAt,
          tipo: d.type,
          detalhe,
          valor: d.value,
          saldo: d.balance,
          idPedidos,
          statusConciliacao:
            d.value > 0
              ? conciliation
                ? conciliation.conciliationStatus
                : 'aguardando_conciliacao'
              : undefined,
        };
      })
    : [];

  return (
    <>
      <PageHeader
        title="Extrato"
        actions={
          <Stack direction="row" gap={2} alignItems="center">
            <Chip
              label={currentDateRangeFilter}
              onClick={handleFilterChipClick}
            />
            <FilterForm<ExtratoFilterForm>
              onFilter={handleFilter}
              onClear={handleResetFilter}
              onClose={handleFilterClose}
              anchorEl={anchorEl}
              appliedFilterCount={0}
              defaultValues={{
                startDate: queryParams.startDate,
                endDate: queryParams.endDate,
                dateExpression: dateExpression,
              }}
              fields={[
                {
                  type: 'date-range',
                  name: 'dateType',
                  startName: 'startDate',
                  endName: 'endDate',
                  formulaName: 'dateExpression',
                  label: 'Data',
                },
              ]}
            />
            {isAdmin && (
              <Button
                disabled={isLoadingRetailerBankAccountTransactions}
                variant="contained"
                onClick={() =>
                  dispatch(
                    supplierActions.setIsRetailerConciliationPanelOpen(true)
                  )
                }
              >
                Conciliação
              </Button>
            )}
          </Stack>
        }
      />
      <Grid2 container paddingBottom={2}>
        <Grid2 xs={6}>
          <CompanyInfo />
        </Grid2>
        <Grid2 xs={6}>
          <BankAccountInfo />
        </Grid2>
      </Grid2>

      <DataTable<ExtratoVarejista>
        columns={columns}
        data={enhancedData}
        isLoading={isLoadingRetailerBankAccountTransactions}
        sx={{
          [`& .${gridClasses.cell}.icon`]: {
            fontSize: '30px',
          },
        }}
      />
      <ResponsiveDialog
        open={isRetailerConciliationPanelOpen}
        maxWidth="lg"
        fullWidth
        onClose={() =>
          dispatch(supplierActions.setIsRetailerConciliationPanelOpen(false))
        }
      >
        <ConciliacaoVarejista />
      </ResponsiveDialog>
    </>
  );
}

export default FornecedorExtratoVarejista;
