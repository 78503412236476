import { useAppSelector } from '../helpers/hooks';

const useUserRole = (ignoreUserView?: boolean) => {
  const { dbUser } = useAppSelector((state) => state.app);
  const { isUserViewActivated } = useAppSelector((state) => state.devTools);
  const isAdmin =
    isUserViewActivated && !ignoreUserView
      ? false
      : ['dev', 'adm'].includes(dbUser?.userRole ?? '');
  const isDev =
    isUserViewActivated && !ignoreUserView ? false : dbUser?.userRole === 'dev';
  return { isAdmin, isDev };
};
export default useUserRole;
