import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Models, Supplier } from 'types';

export type RegistrarState = {
  isLoadingOptins: boolean;
  retailerOptin?: Models.RetailerOptin;
};
const initialState: RegistrarState = {
  isLoadingOptins: false,
};

export const registrarSlice = createSlice({
  name: 'registrar',
  initialState,
  reducers: {
    refreshRetailerOptins: (_state, _action: PayloadAction<string>) => {},
    getRetailerOptin: (_state, _action: PayloadAction<string>) => {},
    updateRetailerOptin: (
      _state,
      _action: PayloadAction<{ id: string; body: Supplier.PatchOptinPayload }>
    ) => {},
    createRetailerOptin: (_state, _action: PayloadAction<string>) => {},
    cancelRetailerOptin: (_state, _action: PayloadAction<string>) => {},
    setIsLoadingOptins: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoadingOptins = payload;
    },
    setRetailerOptin: (
      state,
      { payload }: PayloadAction<Models.RetailerOptin>
    ) => {
      state.retailerOptin = payload;
    },
  },
});

export const registrarActions = registrarSlice.actions;

export default registrarSlice.reducer;
